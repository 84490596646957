import React from 'react';
import {View, ScrollView, Text, Pressable} from 'react-native';
import {StyleSheet} from 'react-native';
import {Close} from './icon';
import {semiBold} from '../utils/constants';

export default function Terms({close}) {
  return (
    <View style={styles.container}>
      <View style={{alignItems: 'flex-end'}}>
        <Pressable style={styles.closeButton} onPress={close}>
          <Close color="#454F5B" />
        </Pressable>
      </View>
      <Text style={styles.heading}>Terms & Conditions</Text>
      <ScrollView style={styles.scroll}>
        <Text style={styles.paragraph}>
          INRBonds QuickInvest is a product of Zephyr Financial Publishers Pvt Ltd which facilitates investments in bonds
          for investors. For the transaction to proceed further you are requested to read and accept below terms & conditions:
        </Text>
        <Text style={styles.paragraph}>
          • You have gone through the bond specific details such as issuer, maturity, coupon, risk profile, payment structure
          and taxation and understood the risk involved in investing in this bond.
        </Text>
        <Text style={styles.paragraph}>
          • You entered the correct transaction specification details such as price and quantity.
        </Text>
        <Text style={styles.paragraph}>
          • You agree and acknowledge that transactions done through INRBonds QuickInvest platform is just a facilitation of the
          transaction between the counterparties and will not be treated as direct.
        </Text>
        <Text style={styles.paragraph}>
          • All the transaction that takes place on QuickInvest are done through the Indian Clearing Corporation Limited.
        </Text>
        <Text style={styles.paragraph}>
          • Zephyr Financial does not take responsibility for any credit specific issue on the bond including non-payment of
          interest or maturity proceeds.
        </Text>
        {/* <Text style={styles.paragraph}>
          • Zephyr Financial will charge platform usage fees which ranges from 0.01%-0.1%
        </Text> */}
      </ScrollView>
    </View>
  );
}

export const styles = StyleSheet.create({
  heading: {
    fontSize: 26,
    fontFamily: semiBold,
    color: '#454F5B',
  },
  paragraph: {
    color: '#637381',
    lineHeight: 22,
    marginTop: 10,
  },
  container: {
    backgroundColor: 'white',
    paddingHorizontal: 20,
    paddingVertical: 10,
    borderRadius: 10,
    marginTop: 10,
    elevation: 8,
    maxWidth: '94%',
    maxHeight: '94%',
    alignSelf: 'center',
  },
  scroll: {
    height: 400,
  },
  closeButton: {
    width: 40,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
