import React from 'react';
import {
  SafeAreaView,
  View,
  Pressable,
  ScrollView,
  Text,
  Image,
} from 'react-native';

import {isMobile, isNative} from '../../utils/constants';
import styles from './styles';
import LinearBackground from '../../components/linearBackground';
import {HeroImage, AppStoreImage} from '../../components/heroImage';
import {Phone} from '../../components/phone';
import EducationImage from '../../assets/images/education.png';
import LowYieldImage from '../../assets/images/low-yield.png';
import TaxfreeImage from '../../assets/images/tax-free.png';
import HeroAd from '../../assets/images/app-ad.png';

export const Home = ({navigation}) => {
  const showListing = () =>
    isMobile
      ? navigation.navigate('How It Works')
      : navigation.navigate('Listing');

  return (
    <>
      <ScrollView style={{height: 400}}>
        <LinearBackground style={styles.container}>
          <SafeAreaView style={{flex: 1}}>
            <View style={styles.heroSection}>
              <View style={styles.heroLeft}>
                <Text style={styles.heroText}>Save better through bonds</Text>
                <Text style={styles.heroPara}>
                  INRBonds makes it easy to invest in bonds, to help achieve
                  your savings goal.
                </Text>
                {isMobile ? null : (
                  <Pressable onPress={showListing}>
                    <LinearBackground
                      style={styles.heroButton}
                      colors={['#31BC98', '#4DBC31']}>
                      <Text style={styles.discoverButtonText}>
                        Explore INRBonds
                      </Text>
                    </LinearBackground>
                  </Pressable>
                )}
              </View>
              {isNative ? null : (
                <View style={styles.heroRight}>
                  {isMobile ? null : (
                    <Image
                      style={
                        isMobile
                          ? {width: '98%', height: 300}
                          : {width: 450, height: 400}
                      }
                      resizeMode="contain"
                      source={HeroAd}
                    />
                  )}
                  <Text style={styles.appStoreText}>Download our app now</Text>
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      width: isMobile ? '95%' : 350,
                    }}>
                    <AppStoreImage
                      width={150}
                      height={50}
                      path={'https://inrbonds.com/icons/app-store.svg'}
                      link={
                        'https://apps.apple.com/in/app/quickinvest-by-inrbonds/id1604873255'
                      }
                    />
                    <AppStoreImage
                      width={169}
                      height={50}
                      path={'https://inrbonds.com/icons/gplay.svg'}
                      link={
                        'https://play.google.com/store/apps/details?id=com.quickinvest.inrbonds'
                      }
                    />
                  </View>
                  {isMobile ? (
                    <Pressable onPress={showListing}>
                      <LinearBackground
                        style={styles.heroButton}
                        colors={['#31BC98', '#4DBC31']}>
                        <Text style={styles.discoverButtonText}>
                          Explore INRBonds
                        </Text>
                      </LinearBackground>
                    </Pressable>
                  ) : null}
                </View>
              )}
            </View>
            <View style={styles.featureSection}>
              <View style={styles.featureImage}>
                <HeroImage width="100%" index={1} />
              </View>
              <View style={styles.featureTextSection}>
                <Text style={styles.featureText}>
                  Choose from a wide range of bonds
                </Text>
                {isMobile ? null : (
                  <Text style={[styles.heroPara, {fontSize: 18}]}>
                    INRBonds provides you a wide choice of bonds like Gsec, PSU
                    bonds, tax free bonds, Corporate bonds and Invits to suit
                    your investment horizon and goals. All in one place.
                  </Text>
                )}
              </View>
            </View>
            <View style={styles.featureSection}>
              <View style={styles.featureTextSectionLeft}>
                <Text style={styles.featureText}>
                  Simple and easy transaction process
                </Text>
                {isMobile ? null : (
                  <Text style={[styles.heroPara, {fontSize: 18}]}>
                    We provide an easy-to-use interface for your investment with
                    full support and complete transparency by enabling you to
                    transfer the money directly to the settlement
                    agency(BSE/NSE).
                  </Text>
                )}
              </View>
              <View style={styles.featureImage}>
                <HeroImage width="100%" index={2} />
              </View>
            </View>
            <View style={styles.featureSection}>
              <View style={styles.featureImage}>
                <HeroImage width="100%" index={3} />
              </View>
              <View style={styles.featureTextSection}>
                <Text style={styles.featureText}>
                  Access to well researched bond information
                </Text>
                {isMobile ? null : (
                  <Text style={[styles.heroPara, {fontSize: 18}]}>
                    INRBonds has strong research team that publishes information
                    that are timely and actionable to make your investments work
                    harder for you. INRBonds has a track record of publishing
                    well researched reports over the past eight years.
                  </Text>
                )}
              </View>
            </View>
            <Pressable
              style={{width: 300, alignItems: 'center', alignSelf: 'center'}}
              onPress={showListing}>
              <LinearBackground
                style={styles.heroButton}
                colors={['#31BC98', '#4DBC31']}>
                <Text style={styles.discoverButtonText}>Know More</Text>
              </LinearBackground>
            </Pressable>
            <View style={{maxWidth: 980, alignSelf: 'center'}}>
              <Text style={styles.quote}>
                INRBonds is an independent bond investment platform which means
                that you get unbiased information to help you choose the right
                bond
              </Text>
            </View>
            {isMobile ? null : (
              <View style={styles.whoIsSection}>
                <Text style={styles.whoIsText}>
                  INRBonds designed for you to Save Better
                </Text>
                <View style={styles.whoIsRow}>
                  <View style={styles.whoIsItem}>
                    <View style={styles.whoIsImage}>
                      <Image
                        style={{width: 140, height: 140}}
                        resizeMode="contain"
                        source={EducationImage}
                      />
                    </View>
                    <Text style={styles.whoIsDescription}>
                      Investors savings for education, monthly or yearly
                      cashflow planning and retirement investment
                    </Text>
                  </View>
                  <View style={styles.whoIsItem}>
                    <View style={styles.whoIsImage}>
                      <Image
                        style={{width: 140, height: 140}}
                        resizeMode="contain"
                        source={LowYieldImage}
                      />
                    </View>
                    <Text style={styles.whoIsDescription}>
                      Investors looking for alternatives to low yield fixed
                      deposits or debt mutual funds
                    </Text>
                  </View>
                  <View style={styles.whoIsItem}>
                    <View style={styles.whoIsImage}>
                      <Image
                        style={{width: 140, height: 140}}
                        resizeMode="contain"
                        source={TaxfreeImage}
                      />
                    </View>
                    <Text style={styles.whoIsDescription}>
                      Investors planning for the real long term, or looking for
                      tax free returns
                    </Text>
                  </View>
                </View>
                <Pressable onPress={showListing}>
                  <LinearBackground
                    style={styles.heroButton}
                    colors={['#31BC98', '#4DBC31']}>
                    <Text style={styles.discoverButtonText}>Get Started</Text>
                  </LinearBackground>
                </Pressable>
              </View>
            )}
          </SafeAreaView>
        </LinearBackground>
      </ScrollView>
      {isMobile ? <Phone /> : null}
    </>
  );
};
