export const collections = [
  {
    text: 'Gender',
    type: 'choice',
    options: ['Male', 'Female'],
  },
  {
    text: 'Occupation',
    type: 'choice',
    options: ['Self Employed', 'Salaried', 'Retired', 'Unemployed'],
  },
  {
    text: 'What is your highest level of Education?',
    type: 'choice',
    options: [
      'Graduation',
      'Post Graduation',
      'Professional Qualification',
      'None of these',
    ],
  },
  {
    text: 'Rate your Financial Knowledge',
    type: 'choice',
    options: ['Poor', 'Below Average', 'Average', 'Excellent'],
  },
  {
    text: 'To you, risk means',
    type: 'choice',
    options: [
      'High Returns',
      'Opportunity',
      'Being Cautious',
      'Possibility of loss',
    ],
  },
  {
    text: 'Do you have any formal financial Education?',
    type: 'choice',
    options: ['Yes', 'No'],
  },
  {
    text: 'What is your annual income ?',
    type: 'choice',
    options: ['Below 10Lacs', '10-30 Lacs', '30-50 Lacs', 'More than 50 Lacs'],
  },
  {
    text: 'How many adults are financially dependent on you ?',
    type: 'choice',
    options: ['None', '1 - 2', '3 or more'],
  },
  {
    text: 'How many children are financially dependent on you ?',
    type: 'choice',
    options: ['None', '1 - 2', '3 or more'],
  },
  {
    text: 'Are you adequetly covered for any major illness that could financially affect you or the people dependent on you ?',
    type: 'choice',
    options: ['Yes', 'No'],
  },
  {
    text: 'How long have you been actively investing ?',
    type: 'choice',
    options: ['Less than 5 Yrs', '5 - 10 Yrs', 'More than 15 Yrs'],
  },
  {
    text: 'Have you experienced losses in investment ?',
    type: 'choice',
    options: ['Yes', 'No'],
  },
  {
    text: 'I believe my investment skills are',
    type: 'choice',
    options: ['Very Good', 'Good', 'Average', 'Poor', 'Very Poor'],
  },
  {
    text: 'When an investment starts to loose money, I',
    type: 'choice',
    options: [
      'Sell it right away',
      'Slowly and cautiosly exit',
      'Wait for it to recover',
      'Call my advisor',
    ],
  },
  {
    text: 'How often do you review/evaluate your portfolio?',
    type: 'choice',
    options: [
      'Everyday',
      'Once a year',
      'Once in 2-3 years',
      'At the time of high volatility',
      'Never',
    ],
  },
  {
    text: 'If you had an opportunity to invest in a high risk high return financial product, how much potential loss will you be willing to bear, as percentage of your total portfolio ?',
    type: 'choice',
    options: ['Less than 10%', '10 - 20%', 'Upto 50%', 'More than 50%'],
  },
  {
    text: 'How willing are you to take risk in general? (5 highest, 4,3,2,1,lowest 0)',
    type: 'range',
    options: ['Select'],
    range: 5,
  },
  {
    text: 'Which of the following instruments do you prefer to invest in? Rank the options according to your preference(5 highest, 4,3,2,1,lowest 0)',
    type: 'range',
    options: [
      'Shares',
      'Fixed Deposits',
      'Mutual Funds',
      'Corporate Bonds',
      'Government Bonds',
      'Gold, Gold bonds, Gold ETF...',
      'Property',
    ],
    range: 5,
  },
];
