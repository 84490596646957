import React from 'react';
import {Svg, Circle, Path, Defs, LinearGradient, Stop} from 'react-native-svg';

export default function PriceImage() {
  return (
    <Svg width="92" height="92" viewBox="0 0 92 92" fill="none">
        <Circle cx="48.4864" cy="48.4865" r="43.5135" fill="#CEEAD5"/>
        <Circle cx="43.5135" cy="43.5135" r="43.5135" fill="url(#paint0_linear_401_450)"/>
        <Path d="M46 25C35.5236 25 27 33.5233 27 44C27 54.4767 35.5236 63 46 63C56.4764 63 65 54.4767 65 44C65 33.5233 56.4764 25 46 25ZM46 62.05C36.0473 62.05 27.95 53.9527 27.95 44C27.95 34.0473 36.0473 25.95 46 25.95C55.9527 25.95 64.05 34.0473 64.05 44C64.05 53.9527 55.9527 62.05 46 62.05Z" fill="white" stroke="white"/>
        <Path d="M45.9999 30.3099C46.2625 30.3099 46.4749 30.0973 46.4749 29.8349V28.0875C46.4749 27.8252 46.2625 27.6125 45.9999 27.6125C45.7374 27.6125 45.5249 27.8252 45.5249 28.0875V29.8349C45.5249 30.0973 45.7374 30.3099 45.9999 30.3099Z" fill="white" stroke="white"/>
        <Path d="M38.4533 29.9813C38.3216 29.754 38.0307 29.6763 37.8044 29.8073C37.5771 29.9386 37.4991 30.2292 37.6304 30.4563L38.5043 31.9696C38.5925 32.122 38.752 32.2071 38.9163 32.2071C38.997 32.2071 39.0786 32.1867 39.1533 32.1436C39.3806 32.0123 39.4585 31.7217 39.3272 31.4946L38.4533 29.9813Z" fill="white" stroke="white"/>
        <Path d="M33.9717 36.5057L32.4586 35.632C32.2313 35.501 31.9414 35.5782 31.8096 35.806C31.6783 36.033 31.7563 36.3236 31.9836 36.4549L33.4967 37.3286C33.5714 37.3717 33.653 37.3921 33.7337 37.3921C33.8979 37.3921 34.0575 37.307 34.1457 37.1546C34.2769 36.9276 34.199 36.637 33.9717 36.5057Z" fill="white" stroke="white"/>
        <Path d="M32.3099 44C32.3099 43.7377 32.0975 43.525 31.8349 43.525H30.0875C29.825 43.525 29.6125 43.7377 29.6125 44C29.6125 44.2623 29.825 44.475 30.0875 44.475H31.8349C32.0975 44.475 32.3099 44.2623 32.3099 44Z" fill="white" stroke="white"/>
        <Path d="M33.4967 50.6715L31.9836 51.5452C31.7563 51.6764 31.6783 51.967 31.8096 52.1941C31.8977 52.3465 32.0573 52.4316 32.2215 52.4316C32.3022 52.4316 32.3839 52.4112 32.4586 52.3681L33.9717 51.4944C34.199 51.3631 34.2769 51.0725 34.1457 50.8454C34.0139 50.6179 33.7226 50.54 33.4967 50.6715Z" fill="white" stroke="white"/>
        <Path d="M39.1533 55.8563C38.9265 55.7248 38.6356 55.8027 38.5043 56.0302L37.6304 57.5436C37.4991 57.7707 37.5771 58.0613 37.8044 58.1925C37.879 58.2357 37.9607 58.2561 38.0414 58.2561C38.2056 58.2561 38.3652 58.171 38.4533 58.0186L39.3272 56.5052C39.4585 56.2782 39.3806 55.9876 39.1533 55.8563Z" fill="white" stroke="white"/>
        <Path d="M45.9999 57.6902C45.7374 57.6902 45.5249 57.9029 45.5249 58.1652V59.9126C45.5249 60.1749 45.7374 60.3876 45.9999 60.3876C46.2625 60.3876 46.4749 60.1749 46.4749 59.9126V58.1652C46.4749 57.9029 46.2625 57.6902 45.9999 57.6902Z" fill="white" stroke="white"/>
        <Path d="M53.4943 56.0302C53.3631 55.8027 53.0717 55.7248 52.8454 55.8563C52.6181 55.9876 52.5402 56.2782 52.6714 56.5052L53.5454 58.0186C53.6335 58.171 53.7931 58.2561 53.9573 58.2561C54.038 58.2561 54.1196 58.2357 54.1943 58.1925C54.4216 58.0613 54.4995 57.7707 54.3683 57.5436L53.4943 56.0302Z" fill="white" stroke="white"/>
        <Path d="M60.0203 51.5452L58.5071 50.6715C58.2799 50.54 57.9895 50.6179 57.8582 50.8454C57.7269 51.0725 57.8049 51.3631 58.0321 51.4944L59.5453 52.3681C59.62 52.4112 59.7016 52.4316 59.7823 52.4316C59.9465 52.4316 60.1061 52.3465 60.1942 52.1941C60.3255 51.967 60.2476 51.6764 60.0203 51.5452Z" fill="white" stroke="white"/>
        <Path d="M59.6902 44C59.6902 44.2623 59.9026 44.475 60.1652 44.475H61.9126C62.1751 44.475 62.3876 44.2623 62.3876 44C62.3876 43.7377 62.1751 43.525 61.9126 43.525H60.1652C59.9026 43.525 59.6902 43.7377 59.6902 44Z" fill="white" stroke="white"/>
        <Path d="M57.8582 37.1546C57.9463 37.307 58.1059 37.3921 58.2701 37.3921C58.3508 37.3921 58.4325 37.3717 58.5071 37.3286L60.0203 36.4549C60.2476 36.3236 60.3255 36.033 60.1942 35.806C60.0625 35.5782 59.7712 35.501 59.5453 35.632L58.0321 36.5057C57.8049 36.637 57.7269 36.9276 57.8582 37.1546Z" fill="white" stroke="white"/>
        <Path d="M54.1943 29.8073C53.9675 29.6763 53.6766 29.754 53.5454 29.9813L52.6714 31.4946C52.5402 31.7217 52.6181 32.0123 52.8454 32.1436C52.9201 32.1867 53.0017 32.2071 53.0824 32.2071C53.2466 32.2071 53.4062 32.122 53.4943 31.9696L54.3683 30.4563C54.4995 30.2292 54.4216 29.9386 54.1943 29.8073Z" fill="white" stroke="white"/>
        <Path d="M51.7816 36.5824C51.7816 36.3201 51.5692 36.1074 51.3066 36.1074H44.9874H43.1073H41.038C40.7754 36.1074 40.563 36.3201 40.563 36.5824C40.563 36.8447 40.7754 37.0574 41.038 37.0574H43.1073H44.9874C46.7275 37.0574 48.1694 38.3412 48.4262 40.0106H43.1073C42.8448 40.0106 42.6323 40.2233 42.6323 40.4856C42.6323 40.7479 42.8448 40.9606 43.1073 40.9606H48.4432C48.235 42.684 46.766 44.0245 44.9874 44.0245H41.038C40.8436 44.0245 40.6687 44.143 40.5969 44.3237C40.525 44.5043 40.5704 44.7108 40.7114 44.8444L49.0842 52.7721C49.1761 52.8591 49.2934 52.9022 49.4108 52.9022C49.5365 52.9022 49.6622 52.8523 49.7559 52.7538C49.9359 52.5633 49.928 52.2628 49.7374 52.0823L42.2306 44.9745H44.9874C47.2903 44.9745 49.1875 43.209 49.3997 40.9606H51.3066C51.5692 40.9606 51.7816 40.7479 51.7816 40.4856C51.7816 40.2233 51.5692 40.0106 51.3066 40.0106H49.3861C49.2427 38.8152 48.6224 37.7665 47.7208 37.0574H51.3066C51.5692 37.0574 51.7816 36.8447 51.7816 36.5824Z" fill="white" stroke="white"/>
        <Defs>
            <LinearGradient id="paint0_linear_401_450" x1="43.5135" y1="-1.71826e-06" x2="75.4234" y2="102.982" gradientUnits="userSpaceOnUse">
                <Stop stopColor="#ADEB5F"/>
                <Stop offset="1" stopColor="#0DE2D6"/>
            </LinearGradient>
        </Defs>
    </Svg>
  );
}
