import {StyleSheet} from 'react-native';
import {isMobile, semiBold} from '../../utils/constants';

const textColor = '#1B2A3C';

export const styles = StyleSheet.create({
  labelFilter: {
    color: '#637381',
    fontFamily: semiBold,
    fontSize: 14,
  },
  filterSection: {
    flexDirection: 'row',
    width: '100%',
    marginTop: 12,
    marginBottom: 8,
    maxWidth: 1220,
    alignSelf: 'center',
    paddingHorizontal: isMobile ? 12 : 0,
  },
  filterButton: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: 'white',
    elevation: 8,
    borderRadius: 6,
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 6,
    paddingHorizontal: 10,
    shadowColor: 'black',
    shadowOpacity: 0.1,
    shadowRadius: 4,
    shadowOffset: {
      width: 2,
      height: 2,
    },
    maxWidth: 140,
  },
  filterText: {color: 'white', fontFamily: 'Inter-SemiBold'},
  typeButton: {
    backgroundColor: '#F5F4F2',
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 12,
    paddingVertical: 6,
    borderColor: '#D9D8D8',
    borderWidth: 2,
    marginRight: 6,
    marginBottom: 6,
  },
  typeButtonCliked: {
    backgroundColor: 'white',
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 6,
    borderColor: '#31BC98',
    borderWidth: 2,
    marginRight: 6,
    marginBottom: 6,
  },
  typeView: {
    height: 40,
    flexDirection: 'row',
    paddingTop: 8,
    width: '100%',
    maxWidth: 1220,
    alignSelf: 'center',
    paddingHorizontal: isMobile ? 12 : 0,
  },
  typeText: {
    color: '#454F5B',
    fontSize: 15,
    fontFamily: 'Inter-SemiBold',
  },
  typeTextClicked: {
    color: '#31BC98',
    fontSize: 15,
    fontFamily: 'Inter-SemiBold',
  },
  container: {
    borderRadius: 4,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  button: {
    width: '30%',
    maxWidth: 160,
    paddingVertical: 6,
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#F5F4F2',
    paddingHorizontal: 12,
  },
  buttonActive: {
    borderColor: '#31BC98',
    borderWidth: 2,
  },
  buttonNotActive: {
    borderColor: '#F5F4F2',
    borderWidth: 2,
  },
  text: {
    fontFamily: 'Inter-Medium',
    fontSize: 14,
  },
  textActive: {
    color: '#637381',
  },
  textNotActive: {
    color: '#637381',
  },
  result: {
    width: '100%',
    maxWidth: 1220,
    alignSelf: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: isMobile ? 12 : 0,
    paddingBottom: 6,
    marginTop: 6,
  },
  resultText: {
    fontFamily: semiBold,
    fontSize: 14,
    color: textColor,
    paddingLeft: 10,
    marginBottom: 6,
  },
  listing: {
    minWidth: isMobile ? '100%' : 1240,
    height: 400,
    paddingHorizontal: 10,
    alignSelf: 'center',
  },
  filterHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  filterTitle: {
    fontFamily: semiBold,
    fontSize: 18,
    color: '#454F5B',
  },
  doneView: {
    flex: 1,
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginTop: 20,
  },
  doneButton: {
    width: '100%',
    height: 45,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#31BC98',
    borderRadius: 4,
    elevation: 4,
    alignSelf: 'center',
  },
  doneText: {
    color: 'white',
    fontSize: 18,
    fontFamily: 'Inter-Semibold',
  },
  childContainer: {
    marginTop: 20,
  },
  title: {
    fontFamily: semiBold,
    fontSize: 22,
    color: '#637381',
    textAlign: 'left',
  },
  buttonText: {
    fontSize: 18,
    color: '#454F5B',
    fontFamily: semiBold,
    textAlign: 'left',
  },
  modalContainer: {
    backgroundColor: '#FFF',
    alignSelf: 'center',
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: {width: 0, height: 6},
    shadowOpacity: 0.25,
    shadowRadius: 10,
    elevation: 5,
    padding: 20,
    position: 'absolute',
    top: 80,
    width: isMobile ? '100%' : 500,
  },
  answerButton: {
    flexDirection: 'row',
    backgroundColor: 'white',
    paddingBottom: 12,
    paddingTop: 12,
    paddingLeft: 20,
    paddingRight: 20,
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
    shadowColor: 'black',
    shadowOpacity: 0.4,
    shadowRadius: 6,
    shadowOffset: {
      width: 4,
      height: 3,
    },
    elevation: 4,
    marginTop: 8,
    marginBottom: 8,
  },
  advancedView: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    paddingTop: 10,
    paddingBottom: 10,
    borderBottomColor: '#E6E6E6',
    borderBottomWidth: 1,
  },
  advancedMarker: {
    backgroundColor: 'white',
    borderRadius: 6,
    height: 18,
    width: 18,
  },
  showAdvanced: {
    width: 44,
    height: 22,
    backgroundColor: '#31BC98',
    borderRadius: 8,
    padding: 2,
    alignItems: 'flex-end',
    marginLeft: 20,
  },
  hideAdvanced: {
    width: 44,
    height: 22,
    backgroundColor: '#B0B5B3',
    borderRadius: 8,
    padding: 2,
    alignItems: 'flex-start',
    marginLeft: 20,
  },
  bondTypeView: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: 290,
    marginBottom: 20,
  },
  filters: {
    backgroundColor: '#525FB8',
    borderRadius: 50,
    width: 20,
    height: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  sort: {
    backgroundColor: 'white',
    padding: 8,
    borderRadius: 4,
    elevation: 4,
    shadowColor: 'black',
    shadowOpacity: 0.1,
    shadowRadius: 4,
    shadowOffset: {
      width: 2,
      height: 2,
    },
    marginLeft: 10,
  },
  toggle: {
    height: 36,
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 20,
    borderRadius: 4,
    margin: 3,
    elevation: 4,
    shadowColor: 'black',
    shadowOpacity: 0.1,
    shadowRadius: 4,
    shadowOffset: {
      width: 2,
      height: 2,
    },
  },
  toggleContainer: {
    flexDirection: 'row',
    overflow: 'hidden',
  },
  buttonSection: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 10,
  },
  checkerTitle: {
    fontFamily: semiBold,
    fontSize: isMobile ? 14 : 18,
    color: textColor,
    maxWidth: 400,
  },
  heroButton: {
    height: 45,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 20,
    marginTop: 20,
    paddingHorizontal: 10,
    width: 175,
  },
  discoverButtonText: {
    color: 'white',
    fontSize: 14,
    fontFamily: semiBold,
    marginHorizontal: 10,
  },
  checkerContainer: {
    alignSelf: 'center',
    borderRadius: 6,
    shadowColor: '#000',
    shadowOffset: {width: 4, height: 6},
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 5,
    padding: 20,
    position: 'absolute',
    top: '30%',
    width: isMobile ? '94%' : 500,
  },
  marketWatchContainer : {
    flexDirection: isMobile ? 'column' : 'row',
  },
  marketWatch : {
    flexDirection: 'row',
    marginLeft: isMobile ? 0 :  4,
    alignItems: 'center',
  },
  marketWatchText : {
    color: '#525fb8',
    marginRight: 6,
    fontFamily: semiBold,
  },
  MWOfferText: {
    fontFamily: semiBold,
    fontSize: 14,
    color: textColor,
  },
});
