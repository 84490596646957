import React, {useState, useEffect} from 'react';
import {Text, View, Pressable, Modal} from 'react-native';
import {Close} from '../../components/icon';
import { styles } from './styles';
import { apiService } from '../../utils/apiService';

export default function FilterModal({
  show,
  setShow,
  selectionChange,
}) {
  const returns = ['0-8', '8-11', '11+'];
  const maturity = ['0-3', '3-7', '7+'];
  const [bondTypes, setBondTypes] = useState([]);
  const [filters, setFilters] = useState([]);

  const isSelected = (type, value) => {return filters.find(elem => ((elem.type === type) && (elem.value === value)));};
  const manageSelection = (type, value) => {
    let newFilters = filters.slice();
    const index = newFilters.findIndex(elem => elem.type === type);
    if (index >= 0){
      if (newFilters[index].value === value) { newFilters.splice(index, 1); }
      else { newFilters[index] = {type: type, value: value}; }
    }
    else { newFilters = [...newFilters, {type: type, value: value}]; }
    setFilters(newFilters);
    selectionChange(newFilters);
  };

  useEffect(() => {if (bondTypes.length <= 0) { apiService.getQITypes().then(types => setBondTypes(types)); } }, [bondTypes]);

  return (
    <Modal animationType="slide" transparent={true} visible={show} >
      <View style={styles.modalContainer}>
        <View style={styles.filterHeader}>
          <Text style={styles.filterTitle}>Filter</Text>
          <Pressable accessibilityRole="button" onPress={() => {setShow(false);}}><Close color="#637381" /></Pressable>
        </View>
        <View style={styles.childContainer}>
          <View style={{marginBottom: 6}}><Text style={styles.labelFilter}>Category</Text></View>
          <View style={styles.bondTypeView} >
            {bondTypes && bondTypes.map((d, i) => (
              <Pressable key={i} accessibilityRole="button" onPress={() => manageSelection('type', d)}
                style={[styles.typeButton, isSelected('type', d) ? styles.buttonActive : styles.buttonNotActive]}>
                    <Text style={[styles.text, isSelected('type', d) ? styles.textActive : styles.textNotActive]}>{d}</Text>
              </Pressable>
            ))}
          </View>
          <View style={{marginBottom: 6}}><Text style={styles.labelFilter}>Returns</Text></View>
          <View style={styles.container}>
            {returns.map((d, i) => (
              <Pressable key={i} accessibilityRole="button" onPress={() => manageSelection('returns', d)}
                style={[styles.button, isSelected('returns', d) ? styles.buttonActive : styles.buttonNotActive]}>
                  <Text style={[styles.text, isSelected('returns', d) ? styles.textActive : styles.textNotActive]}>{d}</Text>
              </Pressable>
            ))}
          </View>
          <View style={{marginBottom: 6}}><Text style={styles.labelFilter}>Maturity</Text></View>
          <View style={styles.container}>
            {maturity.map((d, i) => (
              <Pressable key={i} accessibilityRole="button" onPress={() => manageSelection('maturity', d)}
                style={[styles.button, isSelected('maturity', d) ? styles.buttonActive : styles.buttonNotActive]}>
                  <Text style={[styles.text, isSelected('maturity', d) ? styles.textActive : styles.textNotActive]}>{d}</Text>
              </Pressable>
            ))}
          </View>
        </View>
      </View>
    </Modal>
  );
}
