import React from 'react';
import {SafeAreaView, View, Text, Pressable} from 'react-native';
import LinearBackground from '../../components/linearBackground';
import {styles} from './styles';
import {
  goToExternalLink,
  isMobile,
  dateFormatter,
  currencyFormatter,
} from '../../utils/constants';

export const Payment = ({navigation, route}) => {
  const primary = route.params.market === 'primary';
  const URL = primary
    ? 'https://www.bhimupi.org.in/upi-live-ipo'
    : 'https://www.nseindia.com/products-services/corporate-bonds-clearing-settlement#:~:text=Settlement%20of%20Corporate%20Bond%20Transactions&text=The%20trades%20will%20be%20settled,accounts%20specified%20by%20the%20participants.';

  const getPaymentMessage = () => {
    if (primary) {
      if (route.params.listing === 'exchange') {
        return (
          'INRBonds will create a bid on behalf of you on the IBBS platform by BSE.' +
          ' BSE will send you further communication regarding your bid application.' +
          ' You will receive a UPI mandate on your Bank/UPI App for an amount of ' +
          currencyFormatter.format(route.params.finalAmount) +
          ' Please make sure to accept the UPI mandate with in 24 hrs.'
        );
      } else {
        return 'Thank you very much for your interest. Our representative will get back to you on this shortly.';
      }
    } else {
      return (
        'You have to make payment of ' +
        currencyFormatter.format(route.params.finalAmount) +
        ' towards your purchase on' +
        dateFormatter.format(new Date(route.params.settlementDate)) +
        ' before 15:00Hr. This payment will be made to NSECl (a subsidiary of NAtional Stock Exchange) account' +
        ' with Reserve Bank Of India(IFSC code: XNSE0000001, Account: 8715106).'
      );
    }
  };

  return (
    <SafeAreaView style={{flex: 1}}>
      <LinearBackground
        style={{alignItems: 'center', height: '100%', width: '100%'}}>
        <View
          style={{
            width: '94%',
            alignSelf: 'center',
            maxWidth: 800,
            alignItems: 'center',
          }}>
          <View
            style={{
              alignItems: 'flex-start',
              width: '94%',
              marginTop: 50,
              marginBottom: 20,
            }}>
            <Text style={styles.dealSlipText}> Payment Details </Text>
          </View>
          <View style={isMobile ? styles.containerMobile : styles.container}>
            <View style={styles.logoSection}>
              <Text style={isMobile ? styles.textKeyMobile : styles.textKey}>
                {getPaymentMessage()}
              </Text>
            </View>
          </View>
          <Pressable
            accessibilityRole="button"
            onPress={() => navigation.navigate('Listing', {show: true})}
            style={styles.continueButton}>
            <Text style={{color: 'white', fontSize: 16, fontWeight: '600'}}>
              Continue
            </Text>
          </Pressable>
          {!primary || route.params.listing === 'exchange' ? (
            <Text style={{marginTop: 10}}>
              <Text
                onPress={() => goToExternalLink(URL)}
                style={{color: '#5C6AC4', fontFamily: 'Inter-SemiBold'}}>
                Click here
              </Text>
              {primary
                ? ' for IPO application process using UPI.'
                : ' for exchange circular on the settlement process.'}
            </Text>
          ) : null}
        </View>
      </LinearBackground>
    </SafeAreaView>
  );
};
