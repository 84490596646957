import React, {useState} from 'react';
import {StyleSheet, Pressable, Text, TextInput, View} from 'react-native';
import {useNavigation, useRoute} from '@react-navigation/native';
import {semiBold, regular, GlobalAlert, isMobile, Constants, currencyFormatter} from '../../utils/constants';
import {Plus, Minus, ArrowDown, ArrowUp} from '../icon';
import useGlobalStore from '../../utils/GlobalStateStore';
import { apiService } from '../../utils/apiService';

export const InvestPrimary = ({bond}) => {
  const navigation = useNavigation();
  const route = useRoute();
  const currentUser = useGlobalStore(state => state.loggedInUser);
  const [totalAmount, setTotalAmount] = useState(bond.minQty * bond.faceValue);
  const [applications, setApplications] = useState((bond.series && bond.series.length > 0) ?
    [{...bond.series[0], quantity: bond.minQty, amount: (bond.minQty * bond.faceValue)}]
    : null,
  );

  const onQtyChange = (seriesObj) => {
    if (applications && applications.length > 0){
      let applicationsCopy = [...applications], total = 0;
      const index = applicationsCopy.findIndex(obj => obj.name === seriesObj.name);
      if (index >= 0) {applicationsCopy[index] = seriesObj;}
      else {applicationsCopy.push(seriesObj);}
      applicationsCopy.forEach(element => {total += element.amount;});
      setTotalAmount(total);
      setApplications(applicationsCopy);
    }
  };

  const handleBuy = () => {
    let valid = true;
    const userData = {
      userName: currentUser.userName,
      phoneNo: currentUser.phoneNo,
      firstName: currentUser.firstName,
      email: currentUser.userName};
    applications.forEach(element => ((element.quantity > 0) && (element.quantity < bond.minQty) ? (valid = false) : null));
    if (!valid) {GlobalAlert('Quantity cannot be less than minimum quantity');}
    else {
      apiService.addCustomer( userData ).then(result => {
        if (result) {
          navigation.navigate('DealSlip', {utm_source: route.params.utm_source,
            applications: JSON.stringify(applications), market: 'primary', selectedBond: JSON.stringify(bond)});
        }
      });
  }};

  return (
    <View style={{alignItems: 'center', paddingHorizontal: isMobile ? 8 : 16, paddingTop: 18}}>
      {bond.series.map((d, i) => {
        return (
          <SeriesCard
            key={i}
            series={d}
            faceValue={bond.faceValue}
            minQty={bond.minQty}
            showOpen={i === 0}
            qtyChange={onQtyChange}
          />
        );
      })}
      <View style={styles.container}>
        <View style={{alignItems: 'center', paddingVertical: 16}}>
          <Text style={styles.label}>Total Investment</Text>
          <Text style={styles.price}>{currencyFormatter.format(totalAmount)}</Text>
        </View>
        {(!currentUser || (currentUser.roleId === Constants.NORMAL_USER_ROLE)) ?
        (<Pressable accessibilityRole="button" style={styles.buyButton} onPress={handleBuy} >
          <Text style={{color: 'white', fontSize: 16, fontWeight: '600'}}>Invest Now</Text>
        </Pressable>) : null}
      </View>
    </View>
  );
};

export function SeriesCard({
  series,
  faceValue,
  minQty,
  showOpen = false,
  qtyChange,
}) {
  const [quantity, setQuantity] = useState(showOpen ? minQty : 0);
  const [show, setShow] = useState(showOpen);
  const [warn, setWarn] = useState(false);

  const qtySetter = value => {
    const val = Number.isNaN(parseInt(value, 10)) ? 0 : parseInt(value, 10);
    setQuantity(val);
    if ((val !== 0) && (val < minQty)) {
      setWarn(true);
    }
    if ((val === 0) || (val >= minQty)) {
      setWarn(false);
    }
    let seriesObj = {...series, quantity: val, amount: (val * faceValue)};
    qtyChange(seriesObj);
  };

  const quantityInc = () => qtySetter(parseInt(quantity, 10) + 1);
  const quantityDec = () => {
    if (quantity > minQty) {
      qtySetter(parseInt(quantity, 10) - 1);    }
  };

  return (
    <View style={vcStyle.variantCard}>
      <View style={vcStyle.variantCardTop}>
        <Text style={vcStyle.variantHeading}>{series.name}</Text>
        <View style={vcStyle.variantCardControls}>
          <Pressable style={vcStyle.variantCardButton} onPress={quantityDec} ><Minus /></Pressable>
          <TextInput
            style={vcStyle.variantCount}
            onChangeText={qtySetter}
            value={`${quantity}`}
            keyboardType="numeric"
          />
          <Pressable style={vcStyle.variantCardButton} onPress={quantityInc} ><Plus /></Pressable>
        </View>
        <Text style={vcStyle.variantCardAmount}>{currencyFormatter.format(quantity * faceValue)}</Text>
        <Pressable style={{width: 20}} onPress={() => setShow(!show)}>
          {show ? <ArrowUp /> : <ArrowDown />}
        </Pressable>
      </View>
      <View
        style={{
          backgroundColor: warn ? 'red' : null,
          borderRadius: 6,
          padding: 6,
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 6,
        }}
      >
        <Text style={{fontSize: 12, color: warn ? 'white' : 'black'}}>Minimum quantity : {minQty}</Text>
      </View>
      {show ? (
        <View style={{paddingTop: 12}}>
          {Object.entries(series).map((d, i) => {
            if (d[0] && d[1] && d[0] !== 'name'){
              return (
                <View style={vcStyle.variantRow}>
                  <Text style={vcStyle.variantKey}>{d[0]}</Text>
                  <Text style={vcStyle.variantValue}>{d[1]}</Text>
                </View>
              );
            } else {return null;}
          })}
        </View>
      ) : null}
    </View>
  );
}

const vcStyle = StyleSheet.create({
  variantCard: {
    backgroundColor: '#ffffff',
    borderRadius: 10,
    padding: 12,
    shadowColor: 'black',
    shadowOpacity: 0.2,
    shadowRadius: 4,
    shadowOffset: {
      width: 2,
      height: 2,
    },
    elevation: 4,
    borderWidth: 1,
    borderColor: '#E0E0E0',
    width: isMobile ? '100%' : null,
    minWidth: 340,
    marginBottom: 10,
  },
  variantHeading: {
    fontFamily: semiBold,
    fontSize: 14,
    color: '#454F5B',
    marginBottom: 8,
  },
  variantRow: {
    marginBottom: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  variantKey: {
    fontFamily: regular,
    fontSize: 12,
    color: '#637381',
    marginRight: 6,
    marginBottom: 6,
  },
  variantValue: {
    fontFamily: semiBold,
    fontSize: 14,
    color: '#454F5B',
    marginRight: 6,
  },
  variantCardTop: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minWidth: isMobile ? '100%' :  420,
  },
  variantCardControls: {
    flexDirection: 'row',
  },
  variantCardButton: {
    width: 33,
    height: 30,
    borderWidth: 1,
    borderColor: '#DBDBDB',
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F5F4F2',
    elevation: 4,
    shadowColor: 'black',
    shadowOpacity: 0.2,
    shadowRadius: 4,
    shadowOffset: {
      width: 2,
      height: 2,
    },
    marginBottom: 4,
  },
  variantCount: {
    fontFamily: semiBold,
    textAlign: 'center',
    color: '#454F5B',
    fontSize: 16,
    minWidth: 35,
    maxWidth: 80,
    height: 30,
    backgroundColor: '#CAD9D0',
    borderRadius: 4,
    marginHorizontal: 10,
    padding: 10,
    paddingBottom: 0,
    paddingTop: 0,
  },
  variantCardAmount: {
    fontFamily: semiBold,
    fontSize: 14,
    color: '#454F5B',
    textAlign: 'right',
  },
});

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FFFFFF',
    elevation: 4,
    width: '100%',
    borderRadius: 4,
    padding: 14,
    marginTop: 16,
    shadowColor: 'grey',
    shadowOpacity: 0.2,
    shadowRadius: 4,
    shadowOffset: {
      width: 2,
      height: 2,
    },
  },
  quantityView: {
    flexDirection: 'row',
    width: 158,
    justifyContent: 'space-between',
    marginTop: 18,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 5,
  },
  label: {
    color: '#637381',
    fontSize: 14,
    fontFamily: 'Inter-Regular',
  },
  labelSmall: {
    color: '#637381',
    fontSize: 12,
    fontFamily: 'Inter-Regular',
    marginTop: 6,
  },
  value: {
    fontSize: 14,
    fontFamily: 'Inter-SemiBold',
    color: '#454F5B',
  },
  price: {
    fontSize: 18,
    fontFamily: 'Inter-SemiBold',
    color: '#454F5B',
  },
  offer: {
    fontSize: 14,
    fontFamily: 'Inter-SemiBold',
    color: '#57CC99',
  },
  addButton: {
    width: 44,
    height: 40,
    backgroundColor: 'white',
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
    shadowColor: 'black',
    shadowOpacity: 0.2,
    shadowRadius: 4,
    shadowOffset: {
      width: 2,
      height: 2,
    },
  },
  addInput: {
    backgroundColor: '#D3E7D3',
    borderRadius: 4,
    fontSize: 18,
    fontFamily: 'Inter-SemiBold',
    color: '#454F5B',
    padding: 10,
    paddingBottom: 0,
    paddingTop: 0,
    maxWidth: 65,
    marginHorizontal: 10,
  },
  buyButton: {
    backgroundColor: '#27AE60',
    height: 45,
    borderRadius: 24,
    alignItems: 'center',
    justifyContent: 'center',
    elevation: 4,
  },
  moreBuyingOptions: {
    alignItems: 'center',
    paddingTop: 14,
    paddingBottom: 14,
  },
  moreBuyingButton: {
    color: '#525FB8',
    fontSize: 16,
    fontFamily: 'Inter-SemiBold',
  },
  accintView: {
    flex: 1,
    paddingLeft: 20,
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  plusView: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingVertical: 5,
  },
  amountView: {
    backgroundColor: '#F5F4F2',
    borderRadius: 8,
    paddingHorizontal: 10,
    marginTop: 5,
    paddingVertical: 8,
  },
  principalamtView: {
    flex: 1,
    paddingLeft: 20,
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
});
