import {StyleSheet} from 'react-native';
import {isMobile, semiBold} from '../../utils/constants';

export const styles = StyleSheet.create({
  logo: {
    width: 45,
    height: 45,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50,
    backgroundColor: '#F1F1F1',
  },
  value: {
    color: '#454F5B',
    fontSize: 15,
    fontFamily: semiBold,
  },
  header: {
    flexDirection: 'row',
    elevation: 6,
    width: '100%',
    maxWidth: 1100,
    alignItems: 'center',
    backgroundColor: 'white',
    padding: 10,
  },
  background: {
    width: '100%',
    paddingHorizontal: 10,
    paddingBottom: 16,
    alignItems: 'center',
    height: '100%',
  },
  bondView: {
    flexDirection: isMobile ? 'column' : 'row',
    width: '100%',
    maxWidth: 1100,
    alignItems: isMobile ? 'flex-start' : 'flex-start',
    justifyContent: 'center',
    marginBottom: 20,
  },
  labelText: {
    fontSize: isMobile ? 16 : 18,
    fontFamily: semiBold,
    fontWeight: '600',
    marginBottom: 12,
    color: '#454F5B',
  },
  similarBonds: {
    width: '100%',
    maxWidth: 1100,
    flexDirection: isMobile ? 'column' : 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  buyButton: {
    backgroundColor: '#525FB8',
    height: 45,
    borderRadius: 6,
    alignItems: 'center',
    justifyContent: 'center',
    elevation: 4,
    marginTop: 10,
    marginBottom: 10,
  },
});
