import React from 'react';
import LinearGradient from 'react-native-web-linear-gradient';

export default function LinearBackground({children, style, colors = ['#ECF3EC', '#E3EEF0', '#D8E8F5']}) {
  return (
    <LinearGradient colors={colors} style={style}>
      {children}
    </LinearGradient>
  );
}
