import React, {useState, useEffect} from 'react';
import {Card} from '../../components/card';
import {
  Text,
  View,
  FlatList,
  Pressable,
  SafeAreaView,
} from 'react-native';
// import {CptyLogo} from '../../components/cptyLogo';
import {useRoute} from '@react-navigation/native';

import {styles} from './styles';
import useGlobalStore from '../../utils/GlobalStateStore';
import {
  loginStates,
  isMobile,
  semiBold,
  subscriptionOptions,
  GlobalAlert,
} from '../../utils/constants';
import LinearBackground from '../../components/linearBackground';
import FilterModal from './filterModal';
import CheckerModal from '../../components/checkerModal';
import {Phone} from '../../components/phone';
import {FilterIcon, FunnelIcon} from '../../components/icon';
import SortModal from './sortModal';
import {apiService} from '../../utils/apiService';

export const Listing = ({navigation}) => {
  const route = useRoute();
  const [bonds, setBonds] = useState([]);
  const [filters, setFilters] = useState([]);
  const [primary, setPrimary] = useState(false);
  const [showDiscover, setShowDiscover] = useState(false);
  const [sortVisible, setSortVisible] = useState(false);
  const loginStage = useGlobalStore(state => state.loginStage);
  const currentUser = useGlobalStore(state => state.loggedInUser);
  const [showChecker, setShowChecker] = useState(false);
  const [item, setSelectedItem] = useState();
  const [gSec, setGSec] = useState(false);
  const active = currentUser && (subscriptionOptions.QuickInvestSubscription & currentUser.validSubscription);

  const handleCardPress = selectedItem => {
    if (loginStage === loginStates.LOGGED_IN) { checkerCallback(selectedItem); }
    else {
      setSelectedItem(selectedItem);
      setShowChecker(true);
    }
  };

  const checkerCallback = selectedItem => {
    if (selectedItem) {
      navigation.navigate('Detail', {
        id: primary ? selectedItem._id : selectedItem.isinNo,
        market: primary ? 'primary' : 'secondary',
      });
    } else if (item) {
      navigation.navigate('Detail', {
        id: primary ? item._id : item.isinNo,
        market: primary ? 'primary' : 'secondary',
      });
    } else { GlobalAlert('Oops! Something went wrong. Please try again'); }
  };

  const handleSortChange = v => {
    const data = bonds;
    if (v === 0) {
      setBonds(bonds);
    } else if (v === 1) {
      setBonds(
        [...data].sort(function (a, b) {
          return a.tenor - b.tenor;
        }),
      );
    } else if (v === 2) {
      setBonds(
        [...data].sort(function (a, b) {
          return b.tenor - a.tenor;
        }),
      );
    } else if (v === 3) {
      setBonds(
        [...data].sort(function (a, b) {
          return parseFloat(a.bestOffer.ytm) - parseFloat(b.bestOffer.ytm);
        }),
      );
    } else if (v === 4) {
      setBonds(
        [...data].sort(function (a, b) {
          return parseFloat(b.bestOffer.ytm) - parseFloat(a.bestOffer.ytm);
        }),
      );
    }
  };

  const selectionChange = newFilters => setFilters(newFilters);

  useEffect(() => {
    if (primary) { apiService.getActiveIPOs().then(data => setBonds(data)); }
    else {
      if (route.params && route.params.cpty) { filters.cptyId = encodeURIComponent(route.params.cpty); }
      apiService.getActiveBonds(filters, gSec ? 'gSec' : '').then(data => setBonds(data));
    }
  }, [primary, gSec, filters]);

  return (
    <SafeAreaView style={{flex: 1}}>
      {primary ? null : (
        <>
          <SortModal show={sortVisible} setShow={setSortVisible} onChange={handleSortChange} />
          <FilterModal show={showDiscover} setShow={setShowDiscover} selectionChange={selectionChange} />
        </>
      )}
      <CheckerModal show={showChecker} setShow={setShowChecker} proceedCallback={checkerCallback} />
      <LinearBackground style={{width: '100%', height: '100%'}}>
        <View style={styles.result}>
          <View style={styles.toggleContainer}>
            <Pressable
              style={[
                styles.toggle,
                {
                   backgroundColor: !primary && !gSec ? '#525FB8' : 'white',
                },
              ]}
              onPress={() => {
                setPrimary(false);
                setBonds([]);
                setGSec(false);
              }}>
              <Text
                style={{
                  color: !primary && !gSec ? 'white' : '#525FB8',
                  fontFamily: semiBold,
                }}>
                Bonds
              </Text>
            </Pressable>
             <Pressable
              style={[
                styles.toggle,
                {
                  backgroundColor: gSec ? '#525FB8' : 'white',
                },
              ]}
              onPress={() => {
                setPrimary(false);
                setBonds([]);
                setGSec(true);
              }}>
              <Text
                style={{
                  color: gSec ? 'white' : '#525FB8',
                  fontFamily: semiBold,
                }}>
                G-Sec
              </Text>
            </Pressable>
            <Pressable
              style={[
                styles.toggle,
                {backgroundColor: primary ? '#525FB8' : 'white'},
              ]}
              onPress={() => {
                setPrimary(true);
                setBonds([]);
                setGSec(false);
              }}>
              <Text
                style={{
                  color: primary ? 'white' : '#525FB8',
                  fontFamily: semiBold,
                }}>
                IPO
              </Text>
            </Pressable>
          </View>
          {/* {(Platform.OS == 'web') && (
            <>
              {currentUser && currentUser.referredBy && (
                <View
                  style={{
                    flex: 1,
                    justifyContent: 'flex-start',
                    marginLeft: 20,
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <Text
                    style={{marginRight: 10, color: '#1B2A3C', fontSize: 14}}>
                    Exclusively from
                  </Text>
                  <CptyLogo name={currentUser.referredBy} />
                </View>
              )}
            </>
          )} */}
          <View style={{flexDirection: 'row'}}>
            {isMobile || primary ? null : (
              <Pressable
                accessibilityRole="button"
                style={styles.filterButton}
                onPress={() => setShowDiscover(true)}>
                <FunnelIcon />
                {filters.length === 0 ? null : (
                  <View style={styles.filters}>
                    <Text style={styles.filterText}>{filters.length}</Text>
                  </View>
                )}
              </Pressable>
            )}
            {primary ? null : (
              <Pressable
                accessibilityRole="button"
                style={styles.sort}
                onPress={e => setSortVisible(!sortVisible)}>
                <FilterIcon />
              </Pressable>
            )}
          </View>
        </View>
        <View style={styles.result}>
          <Text style={styles.resultText}>
            Showing {bonds ? bonds.length : 0} bonds.
          </Text>
          {/* {Platform.OS !== 'web' && (
            <>
              {currentUser && currentUser.referredBy && (
                <View>
                  <Text
                    style={{
                      color: '#1B2A3C',
                      fontSize: isMobile ? 12 : 14,
                      marginLeft: 6,
                    }}>
                    Exclusively from
                  </Text>
                  <View style={{marginTop: -12}}>
                    <CptyLogo name={currentUser.referredBy} />
                  </View>
                </View>
              )}
            </>
          )} */}
        </View>
        {bonds && bonds.length > 0 ? (
          <FlatList
            style={styles.listing}
            data={bonds}
            renderItem={({item, separators}) => (
              <Card
                bond={item}
                isPrimary={primary}
                onPress={() => handleCardPress(item)}
                loggedIn={loginStage === loginStates.LOGGED_IN}
                validSubscription={active}
              />
            )}
            keyExtractor={item => item._id}
            numColumns={isMobile ? 1 : 3}
          />
        ) : (
          <View style={styles.result}>
            <Text style={styles.resultText}>
              Sorry! No results found. Watch out this space for exciting
              investment opportunities.
            </Text>
          </View>
        )}
        {isMobile ? <Phone /> : null}
      </LinearBackground>
    </SafeAreaView>
  );
};
