import React from 'react';
import {NavigationContainer, getPathFromState} from '@react-navigation/native';
import Navigator from './Navigator';

const customGetPathFromState = (state, config) => {
  const newPath = '/quickInvest' + getPathFromState(state, config);
  return newPath;
};

const linking = {
  getPathFromState(state, config) {
    return customGetPathFromState(state, config);
  },
};

const App = () => {
  return (
    <NavigationContainer linking={linking} headerMode="screen">
    {/* <NavigationContainer headerMode="screen"> */}
      <Navigator />
    </NavigationContainer>
  );
};

export default App;
