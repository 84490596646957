import React from 'react';
import {Text, View, Pressable, Modal} from 'react-native';
import {goToExternalLink, isNative} from '../utils/constants';
import {Close} from './icon';
import {styles} from '../screens/listing/styles';
import LinearBackground from './linearBackground';
import {useNavigation} from '@react-navigation/native';

export default function CheckerModal({
  show,
  setShow,
  proceedCallback,
}) {
  const navigation = useNavigation();

  const handleAction = action => {
    setShow(false);
    if (action === 'login') {
      navigation.navigate('Login', {
        nextScreen: isNative ? 'Listing' : encodeURI(window.location.href),
      });
    } else if (action === 'subscribe') {
      goToExternalLink('https://inrbonds.com/signup');
    } else {
      proceedCallback();
    }
  };

  return (
    <Modal animationType="slide" transparent={true} visible={show}>
      <LinearBackground style={styles.checkerContainer}>
        <View style={{flexDirection: 'row', justifyContent: 'flex-end'}}>
          <Pressable
            accessibilityRole="button"
            onPress={() => {
              setShow(false);
            }}>
            <Close color="#637381" />
          </Pressable>
        </View>
        <ButtonSection
          title="Already a subscriber?"
          text="Login"
          onPress={() => handleAction('login')}
        />
        <ButtonSection
          title="I am new here"
          text="Register"
          onPress={() => handleAction('subscribe')}
        />
        <ButtonSection
          title="Proceed as Guest"
          text="Continue"
          onPress={() => handleAction('continue')}
        />
      </LinearBackground>
    </Modal>
  );
}

export function ButtonSection({text, onPress, title}) {
  return (
    <View style={styles.buttonSection}>
      <View style={{flex: 1}}>
        <Text style={styles.checkerTitle}>{title}</Text>
      </View>
      <Pressable onPress={onPress}>
        <LinearBackground
          style={styles.heroButton}
          colors={['#31BC98', '#4DBC31']}>
          <Text style={styles.discoverButtonText}>{text}</Text>
        </LinearBackground>
      </Pressable>
    </View>
  );
}
