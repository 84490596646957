import React, {useState, useRef} from 'react';
import {
  View,
  ScrollView,
  Text,
  Pressable,
  SafeAreaView,
  Modal,
  useWindowDimensions,
} from 'react-native';
import validator from 'validator';
// import DownLoadPDF from '../../components/downLoadPDF';
import {styles} from './styles';
import useGlobalStore from '../../utils/GlobalStateStore';
import {
  GlobalAlert,
  isMobile,
  isNative,
  dateFormatter,
  currencyFormatter,
} from '../../utils/constants';
import LinearBackground from '../../components/linearBackground';
import Terms from '../../components/terms';
import {DealSlipPrimary} from '../../components/dealslip/primary';
import {DealSlipSecondary} from '../../components/dealslip/secondary';
import {apiService} from '../../utils/apiService';

export const DealSlip = ({navigation, route}) => {
  const {height} = useWindowDimensions();
  const primaryFormRef = useRef();
  const [modalVisible, setModalVisible] = useState(false);
  const currentUser = useGlobalStore(state => state.loggedInUser);
  const selectedOrder =
    route.params.selectedOrder && JSON.parse(route.params.selectedOrder);
  const selectedBond =
    route.params.selectedBond && JSON.parse(route.params.selectedBond);
  const selectedApplications =
    route.params.applications && JSON.parse(route.params.applications);
  const primary = route.params.market === 'primary';
  const dealValue = primary
    ? 0
    : ((selectedOrder.pricePerHundred * selectedBond.faceValue) / 100) *
      parseInt(route.params.quantity, 10);
  const accruedInterest = primary
    ? 0
    : selectedBond.accruedInterest * parseInt(route.params.quantity, 10);
  const stampDuty = primary
    ? 0
    : Math.round(((dealValue + accruedInterest) * 0.0001) / 100);
  let primaryTotal = 0;
  if (primary) {
    selectedApplications.forEach(element => {
      primaryTotal += element.amount;
    });
  }
  const totalConsideration = primary
    ? primaryTotal
    : dealValue + accruedInterest + stampDuty;
  const LoginRouteParams = {
    ...route.params,
    nextScreen: isNative ? route.name : encodeURI(window.location.href),
  };

  const validateInput = data => {
    if (!data.firstName || validator.isEmpty(data.firstName)) {
      GlobalAlert('First Name is mandatory');
    } else if (!data.lastName || validator.isEmpty(data.lastName)) {
      GlobalAlert('Last Name is mandatory');
    } else if (!data.phoneNo || !validator.isMobilePhone(data.phoneNo)) {
      GlobalAlert('Please input valid mobile number');
    } else if (!data.email || !validator.isEmail(data.email)) {
      GlobalAlert('Please input valid email address');
    } else if (selectedBond.listing === 'exchange') {
      if (!data.upiId || validator.isEmpty(data.upiId)) {
        GlobalAlert('Please input valid UPI Id');
      } else if (!data.pan || validator.isEmpty(data.pan)) {
        GlobalAlert('Please input valid PAN number');
      } else if (!data.dpId || validator.isEmpty(data.dpId)) {
        GlobalAlert('Please input valid DP Id');
      } else if (!data.clientId || validator.isEmpty(data.clientId)) {
        GlobalAlert('Please input valid Client Id');
      } else { return true; }
    } else { return true; }
    return false;
  };

  const applyIPO = async () => {
    const data = primaryFormRef.current.getFormData();
    if (validateInput(data)) {
      if (currentUser || (await apiService.validateOtp(data.phoneNo))) {
        data.applications = selectedApplications;
        if (route.params.utm_source && route.params.utm_source.length > 0) { data.referredBy = route.params.utm_source; }
        data.nameOfIssuer = selectedBond.nameOfIssuer;
        apiService
          .applyIPO(data)
          .then(response => {
            if (response === 'Sucessful') {
              navigation.navigate('Payment', {
                market: 'primary',
                finalAmount: totalConsideration,
                listing: selectedBond.listing,
              });
            }
          }).catch(error => { console.log(error); });
      } else {
        GlobalAlert('Invalid Input');
      }
    }
  };

  const proceedWithContactUs = data => {
    if (validateInput(data)) {
      apiService
        .validateOtp(data.phoneNo)
        .then(result => {
          if (result) {
            data.comment = JSON.stringify(route.params.selectedOrder);
            if (route.params.utm_source && route.params.utm_source.length > 0) { data.referredBy = route.params.utm_source; }
            apiService
              .sendContactUs(data)
              .then(() =>
                navigation.navigate('Payment', {
                  market: 'primary',
                  finalAmount: totalConsideration,
                  listing: selectedBond.listing,
                }),
              );
          } else {
            GlobalAlert('Invalid Input');
          }
        }).catch(error => { console.log(error); });
    }
  };

  const confirmDeal = async () => {
    if (!currentUser) {
      const data = primaryFormRef.current.getFormData();
      apiService.getUserData(data.email).then(result => {
        if (result && (result.email.toLowerCase() === data.email.toLowerCase())) {
          GlobalAlert('You have a valid account with us. Request you to login with your email to proceed.');
          navigation.navigate('Login', LoginRouteParams);
        }
        else { proceedWithContactUs(data); }
      }).catch(error => {
        console.log(error);
        proceedWithContactUs(data);
      });
    } else {
      navigation.navigate('Payment', {
        market: 'secondary',
        orderId: selectedOrder._id,
        finalAmount: dealValue + accruedInterest + stampDuty,
        settlementDate: selectedBond.settlementDate,
      });
    }
  };

  if (!selectedBond) {
    return null;
  } else {
    return (
      <SafeAreaView style={{flex: 1}}>
        <LinearBackground
          style={{width: '100%', height: height, paddingBottom: 16}}>
          {/* <View style={styles.header}>
            <View style={styles.headerText}>
              <View>
                <Text style={styles.label}>Date</Text>
                <Text style={styles.label}>
                  {dateFormatter.format(new Date())}
                </Text>
              </View>
            </View>
          </View> */}
          <View style={styles.printView} nativeID="printArea">
            <View
              style={{
                paddingHorizontal: 20,
                paddingVertical: 10,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
              <View>
                <Text style={styles.profile}>
                  Dear {currentUser ? currentUser.firstName : 'Sir/Madam'},
                </Text>
                <Text style={styles.label}>
                  Please{currentUser ? ' find ' : ' provide '}below details for your
                  purchase of {selectedBond.nameOfIssuer} bonds:
                </Text>
              </View>
              <View>
                <Text style={styles.label}>
                  Date {dateFormatter.format(new Date())}
                </Text>
              </View>
            </View>
            <View style={styles.container}>
              <ScrollView style={{height: height - (isMobile ? 370 : 298)}}>
                {primary || !currentUser ? (
                  <DealSlipPrimary
                    ref={primaryFormRef}
                    listing={!primary ? 'unlisted' : selectedBond.listing}
                    applications={selectedApplications}
                    currentUser={currentUser}
                  />
                ) : (
                  <DealSlipSecondary
                    selectedBond={selectedBond}
                    selectedOrder={selectedOrder}
                    quantity={route.params.quantity}
                  />
                )}
              </ScrollView>
            </View>
          </View>
          <View style={styles.confirmSection}>
            <View style={styles.pricingSection}>
              <Text style={styles.label}>Total Consideration</Text>
              <Text style={styles.price}>
                {currencyFormatter.format(totalConsideration)}
              </Text>
            </View>
            <View>
              <Pressable
                style={styles.continueButton}
                onPress={primary ? applyIPO : confirmDeal}>
                <Text style={{color: 'white', fontSize: 16, fontWeight: '600'}}>
                  Continue
                </Text>
              </Pressable>
              <Text style={{paddingBottom: 10, paddingTop: 8}}>
                By continuing, you agree to our{' '}
                <Text
                  onPress={() => setModalVisible(true)}
                  style={{color: '#5C6AC4'}}>
                  Terms and Conditions
                </Text>
              </Text>
            </View>
          </View>
          <Modal
            animationType="slide"
            transparent={true}
            visible={modalVisible}
            onRequestClose={() => {
              setModalVisible(!modalVisible);
            }}>
            <Terms close={() => setModalVisible(false)} />
          </Modal>
        </LinearBackground>
      </SafeAreaView>
    );
  }
};
