import React, {useState} from 'react';
import {Modal, View, StyleSheet, Text, Pressable} from 'react-native';
import {isMobile, semiBold, regular} from '../../utils/constants';
import {Close} from '../../components/icon';

export default function SortModal({show, setShow, onChange}) {
  const [sort, setSort] = useState(0);

  const handleSort = v => {
    if (sort === v) {
      setSort(0);
      onChange(0);
    } else {
      setSort(v);
      onChange(v);
    }
  };
  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={show}
      onRequestClose={() => {
        setShow(!show);
      }}
    >
      <View style={styles.modalContainer}>
        <View style={styles.header}>
          <Text style={styles.title}>SORT BY</Text>
          <Pressable style={styles.closeButton} onPress={e => setShow(!show)}>
            <Close color="#454F5B" />
          </Pressable>
        </View>
        <View style={styles.section}>
          <Text style={styles.subTitle}>Maturity</Text>
          <SortButton
            value={1}
            state={sort}
            onPress={() => handleSort(1)}
            text="Low to High"
          />
          <SortButton value={2} state={sort} onPress={() => handleSort(2)} />
        </View>
        <View style={styles.divider} />
        <View style={styles.section}>
          <Text style={styles.subTitle}>Returns</Text>
          <SortButton
            value={3}
            state={sort}
            onPress={() => handleSort(3)}
            text="Low to High"
          />
          <SortButton value={4} state={sort} onPress={() => handleSort(4)} />
        </View>
      </View>
    </Modal>
  );
}

export function SortButton({onPress, text = 'High to Low', state, value}) {
  return (
    <Pressable
      onPress={onPress}
      style={[styles.button, value === state ? styles.buttonActive : null]}
    >
      <Text style={styles.buttonText}>{text}</Text>
    </Pressable>
  );
}

export const styles = StyleSheet.create({
  modalContainer: {
    width: isMobile ? '100%' : 500,
    backgroundColor: 'white',
    position: 'absolute',
    bottom: isMobile ? 0 : null,
    top: isMobile ? null : 80,
    elevation: 8,
    paddingHorizontal: 16,
    paddingVertical: 10,
    alignSelf: 'center',
    shadowOffset: {width: 0, height: 6},
    shadowOpacity: 0.25,
    shadowRadius: 10,
    borderRadius: isMobile ? 0 : 10,
  },
  header: {
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginBottom: 10,
  },
  title: {
    color: '#454F5B',
    fontFamily: semiBold,
    fontSize: 16,
  },
  subTitle: {
    color: '#637381',
    fontFamily: semiBold,
    fontSize: 14,
  },
  closeButton: {
    width: 30,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    width: '38%',
    maxWidth: 160,
    height: 40,
    backgroundColor: '#F5F4F2',
    alignItems: 'center',
    paddingHorizontal: 12,
    borderRadius: 6,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  buttonActive: {
    borderColor: '#31BC98',
    borderWidth: 2,
  },
  buttonText: {
    fontFamily: regular,
    color: '#637381',
    marginHorizontal: 10,
  },
  section: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 20,
    alignItems: 'center',
  },
  divider: {
    borderWidth: 1,
    borderColor: '#F5F5F5',
  },
});
