import React, {useEffect, useState} from 'react';
import {View, Text, ScrollView, Pressable,   useWindowDimensions} from 'react-native';
import LinearBackground from '../../components/linearBackground';
import {styles} from './styles';
import {Slider} from '@miblanchard/react-native-slider';
import { collections } from './data';
import { GlobalAlert} from '../../utils/constants';
import { apiService } from '../../utils/apiService';

//Main form page in the view
export default function Form({route}) {
  const [pages, setPages] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const {height} = useWindowDimensions(), optionHeight = 100;

  useEffect(() => {
    if (!pages || pages.length <= 0) {
      let newQuestionSet = [], newPages = [], totalHeight = 0;
      //populate pages with different questions given....
      for (let i = 0; i < collections.length; i++) {
        if (collections[i].type === 'choice') { totalHeight += Math.ceil(collections[i].options.length / 5) * optionHeight; }
        else if (collections[i].type === 'range') { totalHeight += (collections[i].options.length * optionHeight); }
        if (totalHeight < (height - 100)) { newQuestionSet.push(collections[i]); }
        else {
          newPages.push(newQuestionSet);
          newQuestionSet = [collections[i]];
          if (collections[i].type === 'choice') { totalHeight = Math.ceil(collections[i].options.length / 5) * optionHeight; }
          else if (collections[i].type === 'range') { totalHeight = (collections[i].options.length * optionHeight); }
        }
      }
      //last page with remaining questions.....
      newPages.push(newQuestionSet);
      setPages(newPages);
    }
  }, [pages]);

  const gotoNextPage = () => {
    if (currentPage < (pages.length - 1)){
      setCurrentPage(currentPage + 1);
    }
    else {
      let qnA = [];
      for (let i = 0; i < pages.length; i++) {
        for (let j = 0; j < pages[i].length; j++) {
          if (answers[i] && answers[i][j]) {
            if (pages[i][j].type === 'choice') { qnA.push({question: pages[i][j].text, answer: answers[i][j]}); }
            else if (pages[i][j].type === 'range') {
              let optionsArray = [];
              for (let k = 0; k < pages[i][j].options.length; k++) {
                optionsArray.push({option: pages[i][j].options[k], answer: answers[i][j][k]});
              }
              qnA.push({question: pages[i][j].text, answer: optionsArray});
            }
          }
        }
      }
      if (qnA && qnA.length > 0) { apiService.updateRiskProfile(route.params.username, qnA); }
      else { GlobalAlert('Nothing to submit!'); }
    }
  };

  const goBackPage = () => { if (currentPage > 0) { setCurrentPage(currentPage - 1); } };

  const questionAnswered = (question, answer, rangeOption) => {
    let newAnswers = answers ? answers.slice() : Array(pages.length), answerSet = null;
    const questionSet = pages[currentPage];
    const questionIndex = questionSet.findIndex(e => e.text === question);
    if (questionIndex >= 0) {
        if (answers[currentPage] && (answers[currentPage].length === questionSet.length)) { answerSet = answers[currentPage]; }
        else { answerSet = Array(questionSet.length); }
        if (questionSet[questionIndex].type === 'choice') { answerSet[questionIndex] = answer; }
        else if (questionSet[questionIndex].type === 'range') {
          let optionSet = null;
          if (answerSet[questionIndex] && (answerSet[questionIndex].length === questionSet[questionIndex].options.length)){
            optionSet = answerSet[questionIndex];
          }
          else { optionSet = Array(questionSet[questionIndex].options.length); }
          const optionIdx = questionSet[questionIndex].options.findIndex(e => e === rangeOption);
          if (optionIdx >= 0) { optionSet[optionIdx] = answer; }
          answerSet[questionIndex] = optionSet;
        }
        newAnswers[currentPage] = answerSet;
    }
    setAnswers(newAnswers);
  };

  const getAnswerFor = (question, rangeOption) => {
    const questionSet = pages[currentPage];
    const questionIndex = questionSet.findIndex(e => e.text === question);
    if (questionIndex >= 0) {
      if (answers[currentPage] && (answers[currentPage].length === questionSet.length)) {
        if (questionSet[questionIndex].type === 'choice') { return answers[currentPage][questionIndex]; }
        else if (questionSet[questionIndex].type === 'range') {
          if (answers[currentPage][questionIndex]
            && (answers[currentPage][questionIndex].length === questionSet[questionIndex].options.length)) {
            const optionIdx = questionSet[questionIndex].options.findIndex(e => e === rangeOption);
            if (optionIdx >= 0) { return answers[currentPage][questionIndex][optionIdx]; }
          }
        }
      }
    }
    return null;
  };

  return (
    <ScrollView style={{height: 400}}>
      <LinearBackground
        style={{
          width: '100%',
          paddingHorizontal: 10,
          paddingBottom: 16,
          justifyContent: 'center', flexDirection: 'row',
        }}>
        <View style={styles.Card}>
          <View style={styles.Header}>
            <Text style={styles.HeaderText}>Risk Profile Questionaire</Text>
          </View>
          <View style={styles.QuestionsGroup}>
            <ProgressBar pages={pages} answers={answers} current={currentPage} />
            {pages && pages.length > 0 && pages[currentPage].map((d, i) => {
              if (d.type === 'choice') {
                return <ChoiceQuestion index={i} question={d} notifyChoice={questionAnswered} getAnswerFor={getAnswerFor}/>;
              }
              else if (d.type === 'range') {
                return <RangeQuestionSet index={i} question={d} notifyChoice={questionAnswered} getAnswerFor={getAnswerFor}/>;
              }
              else { return null; }
            })}
          </View>
          <View style={{flexDirection: 'row', justifyContent: 'space-between', paddingBottom: 10, paddingHorizontal: 16}}>
            <Pressable style={styles.ButtonSecondary} onPress={goBackPage}>
              <Text style={styles.ButtonTextSecondary}>Back</Text>
            </Pressable>
            <Pressable  style={styles.ButtonPrimary} onPress={gotoNextPage}>
              <Text style={styles.ButtonText}>{(currentPage === pages.length - 1) ? 'Submit' : 'Next'}</Text>
            </Pressable>
          </View>
        </View>
      </LinearBackground>
    </ScrollView>
  );
}

//slider component with sub questions
export function WithSlider({question, option, range = 5, notifyChoice, getAnswerFor}) {

  const value = getAnswerFor(question, option);

  const valueChange = (val) => {
    if (notifyChoice) { notifyChoice(question, val[0], option); }
  };

  return (
    <View style={styles.Section}>
      <Text style={styles.QuestionForRange}>{option}</Text>
      <Slider
        trackMarks={Array.from(Array(range).keys())}
        step={1}
        minimumValue={0}
        maximumValue={range}
        value={value > 0 ? value : 0}
        onValueChange={val => valueChange(val)}
        thumbStyle={{backgroundColor: '#525FB8'}}
        renderTrackMarkComponent={TrackMarks}
      />
    </View>
  );
}

//Vertical Lines on the slider to show steps
export function TrackMarks() {
  return (
    <View style={{ height: 10, width: 1, borderRightColor: '#C6C6C6', borderRightWidth: 1 }} />
  );
}

export function RangeQuestionSet ({question = null, notifyChoice, getAnswerFor}){

  if (!question) { return null; }

  return (
    <View style={{marginTop: 10}}>
      <Text style={styles.Question}>{question.text}</Text>
      <View style={styles.RatingSection}>
        {Array.from(Array(question.range + 1).keys()).map((d, i) => <Text>{d}</Text>)}
      </View>
      {question.options.map((d, i) =>
        <WithSlider question={question.text} option={d} range={question.range} notifyChoice={notifyChoice} getAnswerFor={getAnswerFor}/>)
      }
    </View>
  );
}

export function ChoiceQuestion ({question = null, notifyChoice, getAnswerFor}) {

  const selectionChange = (choice) => {
    if (notifyChoice) { notifyChoice(question.text, choice); }
  };

  if (!question) { return null; }
  return (
    <View style={styles.Section}>
      <Text style={styles.Question}>{question.text}</Text>
      <View style={{flexDirection: 'row', flexWrap: 'wrap'}}>
        {question.options.map((d, i) => {
          const selected = getAnswerFor(question.text) === d;
          return (
            <Pressable style={selected ? styles.TabButtonActive : styles.TabButton} onPress={() => selectionChange(d)}>
              <Text style={selected ? styles.TabButtonTextActive : styles.TabButtonText}>{d}</Text>
            </Pressable>
          );
        })}
      </View>
    </View>
  );
}

//Indvidual components for ProgressBar
export function ProgressIndicator({active = false, filled = false, value = 0}) {

  const indicatorStyle = active ? styles.ProgressIndicatorActive : filled ? styles.ProgressIndicatorDone : styles.ProgressIndicator;

  return (
    <View style={indicatorStyle}>
      <Text style={styles.ProgressIndicatorText}>{value}</Text>
    </View>
  );
}

//Progress bar for pagination
export function ProgressBar({pages, answers, current}) {
  return (
    <View style={styles.ProgressBar}>
      <View style={styles.ProgressDivider} />
      <View style={styles.ProgressContainer}>
        {pages.map((d, i) => {
          let filled = true;
          if (answers[i] && answers[i].length > 0) {
            for (let j = 0; j < answers[i].length; j++) {
              //take care of choice question here
              if (!answers[i][j] || answers[i][j].length <= 0) {
                filled = false;
                break;
              }
            }
          }
          else { filled = false; }
          return (<ProgressIndicator active={i === current} filled={filled} value={i + 1} />);
        })}
      </View>
    </View>
  );
}
