import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
    dealSlipContainer: {
      marginTop: 40,
      width: 900,
      marginBottom: 20,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    dealSlipContainerMobile: {
      marginTop: 30,
      width: '90%',
      marginBottom: 20,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    dealSlipText: {
      fontSize: 26,
      color: '#383E56',
      fontWeight: '600',
    },
    container: {
      flexDirection: 'column',
      backgroundColor: 'white',
      borderRadius: 4,
      paddingLeft: 36,
      paddingRight: 36,
      paddingTop: 22,
      paddingBottom: 22,
      marginTop: 10,
      shadowColor: '#000',
      shadowOffset: {
        width: 1,
        height: 3,
      },
      shadowOpacity: 0.15,
      shadowRadius: 6,
      elevation: 5,
    },
    containerMobile: {
      flexDirection: 'column',
      backgroundColor: 'white',
      borderRadius: 4,
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 22,
      paddingBottom: 22,
      marginTop: 10,
      shadowColor: '#000',
      shadowOffset: {
        width: 1,
        height: 3,
      },
      shadowOpacity: 0.15,
      shadowRadius: 6,
      elevation: 5,
    },
    textKey: {
      fontSize: 18,
      color: '#637381',
      lineHeight: 26,
    },
    textKeyMobile: {
      fontSize: 16,
      color: '#637381',
      lineHeight: 22,
    },
    logoSection: {
      borderBottomColor: '#DFE3E8',
      borderBottomWidth: 1,
      paddingBottom: 16,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 18,
    },
    continueButton: {
        backgroundColor: '#27AE60',
        height: 45,
        width: 100,
        borderRadius: 24,
        alignItems: 'center',
        justifyContent: 'center',
        elevation: 4,
        marginTop: 15,
    },
  });
