import React from 'react';
import {Svg, Circle, Path, Defs, LinearGradient, Stop} from 'react-native-svg';

export default function ChoiceImage() {
  return (
    <Svg width="92" height="92" viewBox="0 0 92 92" fill="none">
      <Circle cx="48.4864" cy="48.4865" r="43.5135" fill="#CEEAD5"/>
      <Circle cx="43.5135" cy="43.5135" r="43.5135" fill="url(#paint0_linear_401_435)"/>
      <Path d="M53.933 60.324H40.817C38.005 60.324 31.905 52.193 27.253 45.324C26.443 44.46 26 43.339 26 42.15C26 40.908 26.484 39.741 27.362 38.863C28.238 37.986 29.406 37.502 30.648 37.502C31.888 37.502 33.056 37.986 33.934 38.864L36.694 41.624V26.123C36.694 23.85 38.544 22 40.817 22C43.09 22 44.94 23.85 44.94 26.123V32.513C45.296 32.411 45.673 32.358 46.062 32.358C47.486 32.358 48.744 33.083 49.484 34.182C50.035 33.91 50.654 33.756 51.308 33.756C52.732 33.756 53.99 34.482 54.73 35.581C55.281 35.309 55.9 35.155 56.556 35.155C58.829 35.155 60.679 37.005 60.679 39.277V49.556C60.679 56.582 56.761 60.324 53.933 60.324ZM40.917 57.324H53.933C54.835 57.324 57.679 54.987 57.679 49.556V39.277C57.679 38.658 57.175 38.155 56.556 38.155C55.937 38.155 55.433 38.658 55.433 39.277C55.433 40.106 54.761 40.777 53.933 40.777C53.103 40.777 52.433 40.106 52.433 39.277V37.879C52.433 37.26 51.929 36.756 51.308 36.756C50.689 36.756 50.185 37.26 50.185 37.879C50.185 38.708 49.515 39.379 48.685 39.379C47.857 39.379 47.185 38.708 47.185 37.879V36.481C47.185 35.862 46.681 35.358 46.062 35.358C45.443 35.358 44.94 35.862 44.94 36.481C44.94 37.309 44.269 37.981 43.44 37.981C42.611 37.981 41.94 37.309 41.94 36.481V26.123C41.94 25.504 41.436 25 40.817 25C40.198 25 39.694 25.504 39.694 26.123V45.245C39.694 45.851 39.329 46.398 38.768 46.631C38.206 46.863 37.562 46.735 37.133 46.306L31.812 40.986C31.189 40.362 30.105 40.362 29.483 40.984C29.171 41.297 29 41.71 29 42.15C29 42.589 29.172 43.004 29.482 43.314C29.55 43.382 29.611 43.456 29.665 43.535C33.849 49.721 39.302 56.696 40.917 57.324Z" fill="white"/>
      <Defs>
        <LinearGradient id="paint0_linear_401_435" x1="43.5135" y1="-1.71826e-06" x2="75.4234" y2="102.982" gradientUnits="userSpaceOnUse">
          <Stop stopColor="#ADEB5F"/>
          <Stop offset="1" stopColor="#0DE2D6"/>
        </LinearGradient>
      </Defs>
    </Svg>
  );
}
