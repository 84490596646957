import React from 'react';
import {
  View,
  Text,
  Pressable,
  Modal,
  useWindowDimensions,
  SafeAreaView,
  Platform,
} from 'react-native';
import {styles} from './styles';
import LinearBackground from '../../components/linearBackground';
import {useNavigation, useRoute} from '@react-navigation/native';
import {Close} from '../../components/icon';
import {
  GlobalAlert,
  loginStates,
  subscriptionOptions,
  isMobile,
  isNative,
  dateFormatter,
  Constants,
  goToExternalLink,
} from '../../utils/constants';
import useGlobalStore from '../../utils/GlobalStateStore';
import {StatusCodes} from 'http-status-codes';
import {apiService} from '../../utils/apiService';

export default function Profile({user, onCloseRequest}) {
  const navigation = useNavigation();
  const loginStage = useGlobalStore(state => state.loginStage);
  const setloginStage = useGlobalStore(state => state.setloginStage);
  const route = useRoute();
  const {height} = useWindowDimensions();
  const active = user && (subscriptionOptions.QuickInvestSubscription & user.validSubscription);
  const normalUser = user && (user.roleId === Constants.NORMAL_USER_ROLE);

  const changePassword = () => {
    onCloseRequest();
    navigation.navigate('Login', {
      ...route.params,
      nextScreen: isNative ? route.name : encodeURI(window.location.href),
      changePassword: true,
    });
  };

  const logOut = () => {
    if (loginStage === loginStates.LOGGED_IN) {
      apiService.logout().then(response => {
        if (response.status === StatusCodes.OK) {
          navigation.navigate('Listing');
          setloginStage(loginStates.LOGGED_OUT);
        }
      });
    }
  };

   const handleKyc = () => {
    const userData = {
      userName: user.userName,
      phoneNo: user.phoneNo,
      firstName: user.firstName,
      email: user.userName,
    };

    apiService.addCustomer(userData).then(result => {
      if (result) {
        if (isNative) {
          goToExternalLink(`https://ekyc.inrbonds.com/inrbonds-kyc/new/kyc?name=${user.firstName}&email=${user.userName}&mobile=${user.phoneNo}`);
        }
        else {
          goToExternalLink(`https://ekyc.inrbonds.com/inrbonds-kyc/new/kyc?name=${user.firstName}&email=${user.userName}&mobile=${user.phoneNo}&redirectTo=${window.location.href}`);
        }
      }
    });
  };

  const handleLogout = () => {
    GlobalAlert('Do you want to logout?', logOut, () => {
      return null;
    });
    onCloseRequest();
  };

//  const handleClick = () => {
//    onCloseRequest();
//    Linking.canOpenURL('https://inrbonds.com/products#Quick%20Invest').then(
//      supported => {
//        if (supported) {
//          Linking.openURL('https://inrbonds.com/products#Quick%20Invest');
//        } else {
//          GlobalAlert(
//            'Please visit https://inrbonds.com/products#Quick%20Invest',
//          );
//        }
//      },
//    );
//  };

  if (!user) {
    return null;
  } else {
    return (
      <SafeAreaView style={{flex: 1}}>
        <Modal transparent={true} visible={true} animationType="fade">
          <LinearBackground
            style={[
              styles.containerSideBar,
              {height: height, paddingTop: Platform.OS === 'ios' ? 40 : 0},
            ]}
            colors={['#F5F4F2', '#F5F4F2', '#F5F4F2']}>
            <WebContainer>
              <Pressable accessibilityRole="button" onPress={onCloseRequest}>
                <Close color="#637381" />
              </Pressable>
              <View style={styles.nameSection}>
                <Text style={styles.name}>{user.firstName}</Text>
                <Text style={styles.smallText}>{user.userName}</Text>
                <Text style={styles.smallText}>{user.phoneNo}</Text>
              </View>
              <View style={styles.subscriptionSection}>
                <View>
                  <Text style={styles.subscription}>Subscription</Text>
                  <Text style={styles.smallText}>
                    {active
                      ? `Valid till ${dateFormatter.format(
                          new Date(user.expiryDate),
                        )}`
                      : 'You dont have a subscription'}
                  </Text>
                </View>
                {active ? (
                  <View style={styles.active}>
                    <Text style={styles.activeText}>Active</Text>
                  </View>
                ) : (null
                  //<Pressable onPress={handleClick} style={styles.subscribe}>
                    //<Text style={styles.subscribeText}>Subscribe</Text>
                  //</Pressable>
                )}
              </View>
              <View style={styles.section}>
                <SectionButton
                  name="Change Password"
                  onPress={changePassword}
                />
                {normalUser ? (
                  <>
                    <SectionButton
                      name="KYC"
                      onPress={handleKyc}
                      kycStatus={user.kycStatus}
                    />
                    <SectionButton
                      name="My Investment"
                      onPress={() => {
                        navigation.navigate('Portfolio');
                        onCloseRequest();
                      }}
                    />
                    <SectionButton
                      name="Risk Profiler"
                      onPress={() => {
                        navigation.navigate('Risk Profiler', {username: user.userName});
                        onCloseRequest();
                      }}
                    />
                    {isNative && (
                      <SectionButton
                        name="Privacy Policy"
                        onPress={() => { goToExternalLink('https://inrbonds.com/privacy-policy'); }}
                      />
                    )}
                  </>
                ) : null}
                <SectionButton name="Logout" onPress={handleLogout} />
              </View>
            </WebContainer>
          </LinearBackground>
        </Modal>
      </SafeAreaView>
    );
  }
}

export const WebContainer = ({children, width = 400}) => {
  const viewWidth = isMobile ? '100%' : width;
  return <View style={[styles.wrapper, {width: viewWidth}]}>{children}</View>;
};

export function SectionButton({name = 'Untitled', onPress, kycStatus = null}) {
  return (
    <>
      <Pressable onPress={onPress} style={styles.sectionButton}>
        <Text style={styles.sectionButtonText}>{name}</Text>
        {kycStatus ? <Text style={styles.kycText}>{kycStatus}</Text> : null}
      </Pressable>
    </>
  );
}
