import React, {useState, useEffect} from 'react';
import {StyleSheet, Pressable, Text, TextInput, View} from 'react-native';
import {useNavigation} from '@react-navigation/native';
import {loginStates, Constants, dateFormatter, currencyFormatter, isNative } from '../../utils/constants';
import useGlobalStore from '../../utils/GlobalStateStore';
//import OfferListModal from '../offerListModal';
import {Plus, Minus} from '../icon';
import { apiService } from '../../utils/apiService';
import CheckerModal from '../checkerModal';

export const InvestSecondary = ({bond}) => {
  const navigation = useNavigation();
  const loginStage = useGlobalStore(state => state.loginStage);
  const currentUser = useGlobalStore(state => state.loggedInUser);
  const selectedOrder = bond.offers ? bond.offers.reduce((a, b) => {return parseFloat(a.ytm) > parseFloat(b.ytm) ? a : b;}) : null;
  const valuePerBond = (selectedOrder.pricePerHundred * bond.faceValue) / 100 + parseFloat(bond.accruedInterest);
  const isSOV = bond.body.Rating === 'SOV';
  const minInv = {
    minInv: (selectedOrder && bond) ? ((selectedOrder.pricePerHundred * bond.faceValue * selectedOrder.quantity) / 100) : 0,
    numBonds: selectedOrder ? selectedOrder.quantity : 0,
  };
  const [quantity, setQuantity] = useState(minInv.numBonds);
  const stampDuty = isSOV ? 0 : Math.round(((valuePerBond * quantity) * 0.0001) / 100);

  //const [showMoreOffers, setShowMoreOffers] = useState(false);
  const [showChecker, setShowChecker] = useState(false);

  useEffect(() => setQuantity(minInv.numBonds), [minInv.numBonds]);
  const quantityInc = () => setQuantity(parseInt(quantity, 10) + 1);
  const quantityDec = () => {
    if (quantity > minInv.numBonds) {
      setQuantity(parseInt(quantity, 10) - 1);
    }
  };

  const [warn, setWarn] = useState(false);

  const handleTextInput = e => {
    setQuantity(e);
    if (e < minInv.numBonds) { setWarn(true); }
    else { setWarn(false); }
  };

  const handleBuy = () => {
    const userData = {
      userName: currentUser.userName,
      phoneNo: currentUser.phoneNo,
      firstName: currentUser.firstName,
      email: currentUser.userName};
      if (loginStage === loginStates.LOGGED_IN) {
        apiService.addCustomer( userData ).then(result => {
          if (result) {
            const dealValue = valuePerBond * quantity;
            apiService.newIOI({ orderId: selectedOrder._id, quantity: quantity, amount: dealValue }).then(response => {
              if (response === Constants.SUCESS) {
                checkerCallback();
              }
            });
          }
        });
      } else if (isNative) { checkerCallback(); }
      else { setShowChecker(true); }
  };

  const checkerCallback = () => {
    navigation.navigate('DealSlip', {quantity: quantity, market: 'secondary',
    selectedOrder: JSON.stringify(selectedOrder), selectedBond: JSON.stringify(bond)});
  };

  return (
    <View style={{alignItems: 'center', paddingHorizontal: 16, paddingTop: 18}}>
      <CheckerModal
        show={showChecker}
        setShow={setShowChecker}
        proceedCallback={checkerCallback}
      />
      <Text style={styles.label}>Choose number of bonds you want to buy</Text>
      <View style={styles.quantityView}>
        <Pressable accessibilityRole="button" onPress={quantityDec}>
          <View style={styles.addButton}><Minus /></View>
        </Pressable>
        <TextInput
          style={styles.addInput}
          onChangeText={handleTextInput}
          value={quantity.toString()}
          keyboardType="numeric"
        />
        <Pressable accessibilityRole="button" onPress={quantityInc}>
          <View style={styles.addButton}>
            <Plus />
          </View>
        </Pressable>
      </View>
      <View
        style={{
          backgroundColor: warn ? 'red' : null,
          borderRadius: 6,
          padding: 6,
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 6,
        }}
      >
        <Text style={{fontSize: 12, color: warn ? 'white' : 'black'}}>
          Minimum quantity : {minInv.numBonds}
        </Text>
      </View>
      <View style={styles.container}>
        <View>
          <View style={styles.row}>
            <Text style={styles.label}>Face Value</Text>
            <Text style={styles.value}>
              {currencyFormatter.format(bond.faceValue)}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.label}>Minimum Investment</Text>
            <Text style={styles.value}>
              {currencyFormatter.format(minInv.minInv)}
            </Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={{flex: 1}}>
            <Text style={styles.label}>Yield to Maturity</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.value}>{selectedOrder.ytm}</Text>
          </View>
        </View>
        <View style={styles.amountView}>
          <View style={styles.row}>
            <View style={{flex: 1}}>
              <Text style={styles.label}>Principal Amount</Text>
              <Text style={styles.labelSmall}>
                Market value for {quantity} bond(s)
              </Text>
            </View>
            <View style={styles.principalamtView}>
              <Text style={styles.value}>
                {currencyFormatter.format((selectedOrder.pricePerHundred * bond.faceValue / 100) * quantity)}
              </Text>
            </View>
          </View>
          <View style={styles.plusView}><Plus /></View>
          <View style={styles.row}>
            <View style={{flex: 1}}>
              <Text style={styles.label}>
                Accurued Interest Upto{' '}
                <Text style={{color: '#454F5B', fontFamily: 'Inter-SemiBold'}}>
                  {dateFormatter.format(new Date(bond.settlementDate))}
                </Text>
              </Text>
              <Text style={styles.labelSmall}>
                Interest earned on the bond this year
              </Text>
            </View>
            <View style={styles.accintView}>
              <Text style={styles.value}>{currencyFormatter.format(parseFloat(bond.accruedInterest) * quantity)}</Text>
            </View>
          </View>
          <View style={styles.plusView}><Plus /></View>
          <View style={styles.row}>
            <View style={{flex: 1}}><Text style={styles.label}>Stamp Duty</Text></View>
            <View style={styles.accintView}>
              <Text style={styles.value}>{currencyFormatter.format(stampDuty)}</Text>
            </View>
          </View>
        </View>
        <View style={{alignItems: 'center', paddingVertical: 16}}>
          <Text style={styles.label}>Total Investment</Text>
          <Text style={styles.price}>
            {currencyFormatter.format(
              (valuePerBond * quantity) + (stampDuty),
            )}
          </Text>
        </View>
        {(!currentUser || (currentUser.roleId === Constants.NORMAL_USER_ROLE)) ?
        (<Pressable accessibilityRole="button" style={styles.buyButton} onPress={handleBuy} >
          <Text style={{color: 'white', fontSize: 16, fontWeight: '600'}}>Buy This Bond</Text>
        </Pressable>)
        : null }
        {/*{!isMobile && bond && bond.offers.length > 1 ? (
          <>
            <Pressable
              accessibilityRole="button"
              style={styles.moreBuyingOptions}
              onPress={() => setShowMoreOffers(true)}
            >
              <Text style={styles.moreBuyingButton}>More buying options</Text>
            </Pressable>
            <OfferListModal
              offers={bond.offers}
              faceValue={bond.faceValue}
              show={showMoreOffers}
              setShow={setShowMoreOffers}
            />
          </>
        ) : null}*/}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FFFFFF',
    elevation: 4,
    width: '100%',
    borderRadius: 4,
    padding: 14,
    marginTop: 16,
    shadowColor: 'grey',
    shadowOpacity: 0.2,
    shadowRadius: 4,
    shadowOffset: {
      width: 2,
      height: 2,
    },
  },
  quantityView: {
    flexDirection: 'row',
    width: 158,
    justifyContent: 'space-between',
    marginTop: 18,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 5,
  },
  label: {
    color: '#637381',
    fontSize: 14,
    fontFamily: 'Inter-Regular',
  },
  labelSmall: {
    color: '#637381',
    fontSize: 12,
    fontFamily: 'Inter-Regular',
    marginTop: 6,
  },
  value: {
    fontSize: 14,
    fontFamily: 'Inter-SemiBold',
    color: '#454F5B',
  },
  price: {
    fontSize: 18,
    fontFamily: 'Inter-SemiBold',
    color: '#454F5B',
  },
  offer: {
    fontSize: 14,
    fontFamily: 'Inter-SemiBold',
    color: '#57CC99',
  },
  addButton: {
    width: 44,
    height: 40,
    backgroundColor: 'white',
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
    shadowColor: 'black',
    shadowOpacity: 0.2,
    shadowRadius: 4,
    shadowOffset: {
      width: 2,
      height: 2,
    },
  },
  addInput: {
    backgroundColor: '#D3E7D3',
    borderRadius: 4,
    fontSize: 18,
    fontFamily: 'Inter-SemiBold',
    color: '#454F5B',
    padding: 10,
    paddingBottom: 0,
    paddingTop: 0,
    maxWidth: 80,
    marginHorizontal: 10,
  },
  buyButton: {
    backgroundColor: '#27AE60',
    height: 45,
    borderRadius: 24,
    alignItems: 'center',
    justifyContent: 'center',
    elevation: 4,
  },
  moreBuyingOptions: {
    alignItems: 'center',
    paddingTop: 14,
    paddingBottom: 14,
  },
  moreBuyingButton: {
    color: '#525FB8',
    fontSize: 16,
    fontFamily: 'Inter-SemiBold',
  },
  accintView: {
    flex: 1,
    paddingLeft: 20,
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  plusView: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingVertical: 5,
  },
  amountView: {
    backgroundColor: '#F5F4F2',
    borderRadius: 8,
    paddingHorizontal: 10,
    marginTop: 5,
    paddingVertical: 8,
  },
  principalamtView: {
    flex: 1,
    paddingLeft: 20,
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
});
