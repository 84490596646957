import React from 'react';
import {Svg, Path} from 'react-native-svg';

export default function INRLogo() {
  return (
    <Svg width="150" height="53" viewBox="0 0 200 53" fill="none">
      <Path
        d="M59.375 50.872H72.4216C77.9011 50.872 81.989 46.9145 81.989 41.7829C81.989 38.3038 80.119 35.7815 77.4227 34.4768C78.9014 33.1721 79.8581 31.3021 79.8581 28.9538C79.8581 24.0396 75.8572 19.9951 70.682 19.9951H59.375V50.872ZM65.6808 32.6503V25.7356H70.0732C72.2476 25.7356 73.7262 27.3447 73.7262 29.2147C73.7262 31.1282 72.2476 32.6503 70.0732 32.6503H65.6808ZM65.6808 45.088V37.9994H71.9867C74.3351 37.9994 75.8572 39.565 75.8572 41.5654C75.8572 43.5659 74.3351 45.088 71.9867 45.088H65.6808Z"
        fill="#1B2B47"
      />
      <Path
        d="M115.221 35.4427C115.221 26.6145 108.089 19.4824 99.2611 19.4824C90.4329 19.4824 83.3008 26.6145 83.3008 35.4427C83.3008 44.2709 90.4329 51.403 99.2611 51.403C108.089 51.403 115.221 44.2709 115.221 35.4427ZM89.6501 35.4427C89.6501 30.0501 93.8685 25.5708 99.2611 25.5708C104.654 25.5708 108.872 30.0501 108.872 35.4427C108.872 40.8353 104.654 45.3146 99.2611 45.3146C93.8685 45.3146 89.6501 40.8353 89.6501 35.4427Z"
        fill="#1B2B47"
      />
      <Path
        d="M145.931 50.872V19.9951H139.625V40.1303L123.795 19.9951H118.359V50.872H124.665V30.7368L140.495 50.872H145.931Z"
        fill="#1B2B47"
      />
      <Path
        d="M150.977 50.872H161.153C170.025 50.872 177.026 44.0443 177.026 35.4336C177.026 26.8228 170.025 19.9951 161.153 19.9951H150.977V50.872ZM157.282 45.088V25.7791H161.153C166.85 25.7791 170.677 30.0845 170.677 35.4336C170.677 40.7826 166.85 45.088 161.153 45.088H157.282Z"
        fill="#1B2B47"
      />
      <Path
        d="M177.734 45.88C179.952 49.2721 184.084 51.403 188.998 51.403C195.391 51.403 199.783 47.6195 199.827 42.0965C199.827 37.4432 197 34.573 192.477 32.9204L189.998 32.0071C186.737 30.8329 185.214 30.0067 185.214 28.1366C185.214 26.3101 187.215 25.3969 189.346 25.3969C191.477 25.3969 193.39 26.2666 194.869 28.0497L199.044 23.7443C196.391 20.7871 193.086 19.4824 189.302 19.4824C183.301 19.4824 178.865 22.918 178.865 28.7455C178.865 32.9639 181.518 35.8341 186.476 37.5737L189.085 38.4869C192.129 39.5306 193.39 40.7483 193.39 42.4879C193.39 44.2709 191.738 45.4886 189.259 45.4886C186.737 45.4886 184.084 44.1404 182.562 41.8355L177.734 45.88Z"
        fill="#1B2B47"
      />
      <Path
        d="M62.7111 0.244141H59.375V15.1414H62.7111V0.244141Z"
        fill="#A09EAD"
      />
      <Path
        d="M78.4118 15.1414V0.244141H75.0547V9.43426L67.8159 0.244141H64.9414V15.1414H68.2775V5.93026L75.5373 15.1414H78.4118Z"
        fill="#A09EAD"
      />
      <Path
        d="M80.6641 15.1414H84.0002V8.99364H84.5457L89.4975 15.1414H93.5051L88.2386 8.574C90.1269 7.90257 91.1551 6.66463 91.1551 4.73429C91.1551 2.25841 89.3506 0.244141 86.539 0.244141H80.6641V15.1414ZM84.0002 6.16107V3.30751H86.1404C87.1265 3.30751 87.8399 3.83206 87.8399 4.73429C87.8399 5.59455 87.1265 6.16107 86.1404 6.16107H84.0002Z"
        fill="#A09EAD"
      />
      <Path
        d="M0 47.4619V2.57762C0 1.22295 1.09282 0.123861 2.44592 0.114454C2.44592 0.114454 19.7775 0 19.7791 0C28.9403 0 36.38 7.44126 36.38 16.5868C36.38 18.15 36.1636 19.663 35.7575 21.0977C39.6287 24.3181 42.0981 29.1708 42.0981 34.5894C42.0981 44.2649 34.2257 52.1374 24.5502 52.1374H2.4616C1.10223 52.1374 0 51.0352 0 49.6743V47.4635V47.4619ZM4.92478 5.02356V47.2126H24.5502C31.5101 47.2126 37.1733 41.5493 37.1733 34.5894C37.1733 31.1698 35.8061 28.0623 33.5891 25.7873C30.6101 30.2386 25.538 33.1736 19.7932 33.1736H14.8041C13.4432 33.1736 12.3409 32.0714 12.3409 30.712V19.5047C12.3409 18.1437 13.4432 17.0415 14.8041 17.0415C14.8182 17.0415 14.8339 17.0415 14.8417 17.0431H24.5502C26.9475 17.0431 29.2319 17.526 31.3157 18.3993C31.4082 17.8082 31.4552 17.203 31.4552 16.5868C31.4552 10.1569 26.2247 4.9248 19.7947 4.9248L4.92478 5.02356ZM17.2657 28.2488H19.7932C23.8603 28.2488 27.4461 26.1573 29.5345 22.9932C28.0043 22.3332 26.3188 21.9663 24.5502 21.9663H17.2657V28.2488Z"
        fill="#31BC98"
      />
    </Svg>
  );
}
