import React, {useEffect} from 'react';
import {Linking} from 'react-native';
import useGlobalStore from '../utils/GlobalStateStore';
import {createStackNavigator} from '@react-navigation/stack';
import AsyncStorage from '@react-native-async-storage/async-storage';
//screens
import {Home} from '../screens/home';
import {HowItWorks} from '../screens/home/how';
import {Listing} from '../screens/listing';
import {DetailCard} from '../screens/detail';
import Login from '../screens/login';
import {Update} from '../screens/update';
import {DealSlip} from '../screens/dealslip';
import {Payment} from '../screens/payment';
import {InvestmentScreen} from '../screens/investmentScreen';
import Header from '../components/header';
import KYC from '../screens/profile/kyc';
import Portfolio from '../screens/profile/portfolio';
import Form from '../screens/profile/riskProfiler';
//other imports
import {isNative} from '../utils/constants';
import {useNavigation} from '@react-navigation/native';
import {apiService} from '../utils/apiService';

const Stack = createStackNavigator();

const Navigator = () => {
  const loggedInUser = useGlobalStore(state => state.loggedInUser);
  const setPersistedUser = useGlobalStore(state => state.setPersistedUser);
  const setloginStage = useGlobalStore(state => state.setloginStage);
  const setLoggedInUser = useGlobalStore(state => state.setLoggedInUser);

  const navigation = useNavigation();

  const getURLParams = url => {
    let params = {};
    const urlParams = new URLSearchParams(url.substring(url.indexOf('?')));
    for (const p of urlParams){ params[p[0]] = p[1]; }
    return params;
  };

  const checkInitialURL = async () => {
    const initialUrl = await Linking.getInitialURL();
    if (initialUrl && initialUrl.indexOf('Detail') > 0){ navigation.navigate('Detail', getURLParams(initialUrl)); }
    else if (initialUrl && initialUrl.indexOf('Login') > 0){ navigation.navigate('Login', getURLParams(initialUrl)); }
    else { Linking.addEventListener('url', e => {navigation.navigate('Detail', getURLParams(e.url)); }); }
  };

  useEffect(() => {
    if (isNative){
      apiService.versionCheck().then(result => {
        if (!result){ navigation.navigate('Update'); }
      }).catch(error => {
        console.log(error); navigation.navigate('Update');
      });
    }

    checkInitialURL();

    if (!loggedInUser) {
      AsyncStorage.getItem('@storage_Key').then(userId => {
        if (userId){ setPersistedUser(userId); }
        apiService.login(userId).then(result => {
          setloginStage(result.status);
          if (result.user){ setLoggedInUser(result.user); }
        });
      });
    }
  }, []);

  const customHeader = {
    header: (props) => <Header />,
    headerStyle: {backgroundColor: '#EDF4EC'},
    headerLeft: null,
  };

  const customHeaderUpdate = {
    headerStyle: {backgroundColor: '#EDF4EC'},
    headerLeft: null,
  };

  return (
    <Stack.Navigator>
      {isNative && (
        <Stack.Screen name="Home" component={Home} options={customHeader} />
      )}
      {isNative && (
        <Stack.Screen
          name="How It Works"
          component={HowItWorks}
          options={customHeader}
        />
      )}
      <Stack.Screen name="Listing" component={Listing} options={customHeader} />
      <Stack.Screen
        name="Update"
        component={Update}
        options={customHeaderUpdate}
      />
      <Stack.Screen
        name="Detail"
        component={DetailCard}
        options={customHeader}
      />
      {isNative ? (
        <Stack.Screen
          name="Investment"
          component={InvestmentScreen}
          options={customHeader}
        />
      ) : null}
      <Stack.Screen
        name="DealSlip"
        component={DealSlip}
        options={customHeader}
      />
      <Stack.Screen name="Payment" component={Payment} options={customHeader} />
      <Stack.Screen
        name="Login"
        component={Login}
        options={{headerShown: false}}
      />
      <Stack.Screen name="KYC" component={KYC} options={customHeader} />
      <Stack.Screen
        name="Portfolio"
        component={Portfolio}
        options={customHeader}
      />
      <Stack.Screen
        name="Risk Profiler"
        component={Form}
        options={customHeader}
      />
    </Stack.Navigator>
  );
};

export default Navigator;
