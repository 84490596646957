import React, {useState, useCallback} from 'react';
import {Text, View, Pressable, Modal, TextInput} from 'react-native';
import { GlobalAlert, isNative } from '../../utils/constants';
import {Close} from '../../components/icon';
import {CameraIcon} from '../../components/icon';
import {styles, webStyle} from './styles';
import DocumentPicker from 'react-native-document-picker';
import Validator from 'validator';
import { apiService } from '../../utils/apiService';

export default function UploadModal({show, setShow, which}) {
  const [fileResponse, setFileResponse] = useState(null);
  const [pan, setPan] = useState();
  const [aadhar, setAadhar] = useState();
  const [bankName, setBankName] = useState();
  const [ifsc, setIfsc] = useState();
  const [accno, setAccno] = useState();
  const [dpId, setDpId] = useState();
  const [clientId, setClientId] = useState();

  const uploadFile = () => {
    if (fileResponse == null) {
      GlobalAlert('You have not added any document');
      return;
    }
    let formData = new FormData(), proceed = false;
    formData.append('DOCUMENT', fileResponse, fileResponse.name);
    switch (which) {
      case 'pan': {
        if (Validator.isEmpty(pan)) {GlobalAlert('Please enter a valid PAN no');}
        else {proceed = true; formData.append('PANNO', pan);}
        break;
      }
      case 'aadhar': {
        if (Validator.isEmpty(aadhar)) {GlobalAlert('Please enter a valid ID no');}
        else {proceed = true; formData.append('AADHAR', aadhar);}
        break;
      }
      case 'bank': {
        if (Validator.isEmpty(bankName)) {GlobalAlert('Please enter a valid Bank Name');}
        else if (Validator.isEmpty(ifsc)) {GlobalAlert('Please enter a valid IFSC code');}
        else if (Validator.isEmpty(accno)) {GlobalAlert('Please enter a valid Account Number');}
        else { proceed = true; formData.append('IFSC', ifsc); formData.append('ACCNO', accno); formData.append('NAME', bankName); }
        break;
      }
      case 'demat': {
        if (Validator.isEmpty(dpId)) { GlobalAlert('Please enter a valid DP Id'); }
        else if (Validator.isEmpty(clientId)) { GlobalAlert('Please enter a valid Client Id.'); }
        else { proceed = true; formData.append('DPID', dpId); formData.append('CLIENTID', clientId); }
        break;
      }
      default: break;
    }
    if (proceed) {apiService.uploadKYC(formData).then(() => close()).catch(error => console.log(error)); }
  };

  const handleDocumentSelection = useCallback(async () => {
    await DocumentPicker.pickSingle({presentationStyle: 'fullScreen', allowMultiSelection:false, type: [DocumentPicker.types.images, DocumentPicker.types.pdf]})
    .then(response => setFileResponse(response))
    .catch(error => console.warn(error));
  }, []);

  const humanFileSize = (bytes, dp = 1) => {
    const thresh = 1024;

    if (Math.abs(bytes) < thresh) {return bytes + ' B';}

    const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (
      Math.round(Math.abs(bytes) * r) / r >= thresh &&
      u < units.length - 1
    );
    return bytes.toFixed(dp) + ' ' + units[u];
  };

  const header = (which === 'pan') ? 'PAN' : (which === 'aadhar') ?
    'Address Proof' : (which === 'bank') ? 'Bank Acount' : 'Demat Account';

  const close = e => {
    setShow(false); setFileResponse(null); setPan(null); setAadhar(null); setBankName(null); setIfsc(null); setAccno(null);
    setDpId(null); setClientId(null);
  };

  return (
    <Modal animationType="slide" transparent={true} visible={show} >
      <View style={styles.modalContainer}>
        <Pressable accessibilityRole="button" onPress={close}>
          <Close color="#637381" />
        </Pressable>
        <View style={styles.sectionUpload}>
          <Text style={styles.subscription}>Upload {header} Details</Text>
          {(which === 'pan') ? <Input placeholder={'PAN no'} store={setPan} value={pan}/> : null}
          {(which === 'aadhar') ? <Input placeholder={'ID Number'} store={setAadhar} value={aadhar} keyboard={'numeric'}/> : null}
          {(which === 'bank') ? (<>
            <Input placeholder={'Bank Name'} store={setBankName} value={bankName}/>
            <Input placeholder={'IFSC Code'} store={setIfsc} value={ifsc}/>
            <Input placeholder={'Account Number'} store={setAccno} value={accno} keyboard={'numeric'}/>
          </>) : null}
          {(which === 'demat') ? (<>
            <Input placeholder={'DP Id'} store={setDpId} value={dpId}/>
            <Input placeholder={'Client Id'} store={setClientId} value={clientId} keyboard={'numeric'}/>
          </>) : null}
          <UploadButton onPress={handleDocumentSelection} store={setFileResponse} />
          {fileResponse ? (
            <>
            <Text style={styles.smallText}>{fileResponse && (' ' + fileResponse.name)}</Text>
            <Text style={styles.smallText}>{fileResponse && (' ' + humanFileSize(fileResponse.size))}</Text>
            <Text style={styles.smallText}>{fileResponse && (' ' + fileResponse.type)}</Text>
            </>
          ) : (
            <>
            <Text style={styles.smallText}>Supported formats (JPEG, JPG, PNG, PDF)</Text>
            <Text style={styles.smallText}>Max file size - 1 MB</Text>
            </>
          )}
          <Pressable style={styles.verifyButton} onPress={()=> uploadFile()}>
            <Text style={styles.verifyText}>Submit for verification</Text>
          </Pressable>
          </View>
      </View>
    </Modal>
  );
}

export function UploadButton({onPress, store}) {
  const handleUpload = e => store(e.target.files[0]);

  return isNative ? (
    <Pressable onPress={onPress} style={styles.uploadButton}>
      <Text style={styles.uploadButtonText}>Select Document</Text>
      <CameraIcon />
    </Pressable>
  ) : (
    <label htmlFor="upload" style={webStyle.uploadButton}>
      <input
        style={{visibility: 'hidden', width: 0, height: 0}}
        type="file"
        name="kyc"
        id="upload"
        accept="image/*,.pdf"
        onChange={handleUpload}
      />
      <p style={webStyle.uploadText}>Select Document</p>
      <CameraIcon />
    </label>
  );
}

export function Input({placeholder, keyboard = 'default', store, value} ) {
  return (
    <TextInput
      style={styles.input}
      placeholder={placeholder}
      onChangeText={store}
      value={value}
      keyboardType={keyboard}
    />
  );
}
