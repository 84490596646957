import React, {useEffect, useState} from 'react';
import {View, Text, Pressable, useWindowDimensions, ScrollView} from 'react-native';
import {styles} from './styles';
import LinearBackground from '../../components/linearBackground';
import {useNavigation} from '@react-navigation/native';
import {WebContainer} from '.';
import {Back} from '../../components/icon';
import {isNative} from '../../utils/constants';
import UploadModal from './uploadModal';
import { apiService } from '../../utils/apiService';

export default function KYC({route}) {
  const navigation = useNavigation();
  const [userData, setUserData] = useState({});
  const [showUpload, setShowUpload] = useState(false);
  const [whichUpload, setWhichUpload] = useState('pan');
  const { height} = useWindowDimensions();

  useEffect(() => apiService.getUserData(route.params.username).then(result => setUserData(result)), [showUpload]);

  return (
    <View style={{flex: 1}}>
      <UploadModal show={showUpload} setShow={setShowUpload} which={whichUpload}/>
      <LinearBackground style={{ height: height - 40}}>
      <ScrollView>
      <View style={[styles.container, {marginBottom: 60}]}>
        <WebContainer>
          {isNative && (<Pressable accessibilityRole="button" onPress={() => navigation.goBack()} ><Back color="#637381" /></Pressable>)}
          <KYCItem
            name="PAN"
            description="is the unique ID for reporting your financial transactions for tax purpose."
            user={getDoc(userData, 'pan')}
            onPress={() => {setWhichUpload('pan'); setShowUpload(true);}}
          />
          <KYCItem
            name="Address Proof"
            description="is your proof of residence in the form of AADHAR or Municipal/Electricity Bill."
            user={getDoc(userData, 'aadhar')}
            onPress={() => {setWhichUpload('aadhar'); setShowUpload(true);}}
          />
          <KYCItem
            name="Bank Account Details"
            description="will be used as source of funds for your pay-in/out from transactions."
            user={getDoc(userData, 'bank')}
            onPress={() => {setWhichUpload('bank'); setShowUpload(true);}}
          />
          <KYCItem
            name="Demat Account Details"
            description="will be used for securities credit/debit of your transactions."
            user={getDoc(userData, 'demat')}
            onPress={() => {setWhichUpload('demat'); setShowUpload(true);}}
          />
        </WebContainer>
      </View>
      </ScrollView>
      </LinearBackground>
    </View>
  );
}

export const getDoc = (user, id) => {
  if (user.hasOwnProperty(id)) {
    return user[id];
  } else {
    return null;
  }
};

export function KYCItem({name, description, onPress, user}) {
  return (
    <View style={styles.kycSection}>
      <View style={styles.subscriptionTop}>
        <View style={{maxWidth: '60%'}}>
          <Text style={styles.subscription}>{name}</Text>
          <Text style={styles.smallText}>{description}</Text>
        </View>
        <Pressable onPress={onPress} style={styles.upload}>
          <Text style={styles.uploadText}>
            {user == null ? 'Upload' : 'Upload New'}
          </Text>
        </Pressable>
      </View>
      {user == null
        ? null
        : Object.entries(user).map((d, i) => {
            return <Text style={styles.kycFile}>{`${d[0]} : ${d[1]}`}</Text>;
          })}
    </View>
  );
}
