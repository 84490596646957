import React from 'react';
import {IMAGES_SERVER} from '../utils/constants';

export const HeroImage = ({index}) => {
  const path = IMAGES_SERVER + 'hero' + index + '.svg';
  return <img src={path} alt="Hero Section" />;
};

export const AppStoreImage = ({path, link}) => {
  return (
    <a href={link} target="_blank" rel="noreferrer" alt="app store link">
      <img src={path} alt="App Store Icon" />
    </a>
  );
};
