import React, {useState} from 'react';
import {View, Text, Pressable, StyleSheet} from 'react-native';
import {ScoreBoard, DemoScoreBoard} from './scoreboard';
import {
  isMobile,
  semiBold,
  regular,
  loginStates,
  goToExternalLink,
} from '../utils/constants';
import {ArrowDown, ArrowUp} from './icon';
import {Like, Dislike} from './icon';
import useGlobalStore from '../utils/GlobalStateStore';

export const BondDetail = ({bond, primary}) => {
  const [show, setShow] = useState(isMobile ? false : true);
  const loginStage = useGlobalStore(state => state.loginStage);
  const isSOV = bond.body.Rating === 'SOV';
  const message = (loginStage !== loginStates.LOGGED_IN) ? 'Sign In to see the score' : null;

  return (
    <>
      <View style={styles.card}>
        <View>
          <Text style={styles.infoText}>{bond.description}</Text>
        </View>
        <View style={styles.section}>
          {Object.entries(bond.body).map((d, i) => {
            if (d[0] && d[1]) {
              return (
                <View key={i} style={styles.item}>
                  <Text style={styles.label}>{d[0]}</Text>
                  <Text style={styles.value}>{d[1]}</Text>
                </View>
              );
            } else {
              return null;
            }
          })}
        </View>
        <View style={{flexDirection: 'row'}}>
         {/* {!isSOV && <View style={styles.item}>
            <Text style={styles.label}>INRBonds Risk Scale</Text>
            {message ? (
              <DemoScoreBoard message={message} />
            ) : (
              <ScoreBoard score={bond.creditSpreadScore} />
            )}
          </View>} */}
          {!primary && !isSOV && <LinkToResources bond={bond} />}
        </View>
        {primary ? (
          <>
            <View style={styles.lastTradedInfo}>
              <Text style={styles.similarHistory}>
                The bonds of similar nature are traded at a yield of{' '}
                {bond.latestTradeInfo.ytm}% for {bond.latestTradeInfo.tenor}{' '}
                years maturity
              </Text>
            </View>
            <View style={{flexDirection: 'row'}}>
              {bond.creditPositives ? (
                <Feeling data={bond.creditPositives} />
              ) : null}
              <View style={{width: 5}} />
              {bond.creditNegatives ? (
                <Feeling positive={false} data={bond.creditNegatives} />
              ) : null}
            </View>
            <View style={{marginTop: 10}}>
              {bond.listing === 'exchange' ? (
                <Text style={styles.similarHistory}>
                  These bonds will be listed on{' '}
                  <Text style={{fontFamily: semiBold}}>NSE & BSE</Text> for
                  secondary market transaction
                </Text>
              ) : bond.listing === 'retaildirect' ? (
                <Text style={styles.similarHistory}>
                  These bonds will be available on{' '}
                  <Text style={{fontFamily: semiBold}}>RBI Retail Direct</Text>
                  for secondary market transaction
                </Text>
              ) : (
                <Text style={styles.similarHistory}>
                  These bonds will be unlisted. TDS will be deducted on interest
                  payment.
                </Text>
              )}
            </View>
          </>
        ) : (
          <>
            {isMobile ? (
              <Pressable
                accesibilityRole="button"
                onPress={() => setShow(!show)}>
                <View style={styles.lastTradedButton}>
                  <Text style={styles.lastTradedButtonText}>
                    Last traded information
                  </Text>
                  {show ? <ArrowUp /> : <ArrowDown />}
                </View>
              </Pressable>
            ) : (
              <View style={styles.lastTradedButton}>
                <Text style={styles.value}>Last traded information</Text>
              </View>
            )}
            <View style={[styles.section, {display: show ? 'flex' : 'none'}]}>
              {Object.entries(bond.tradeInfo).map((d, i) => {
                return (
                  <View key={i} style={styles.item}>
                    <Text style={styles.label}>{d[0]}</Text>
                    <Text style={styles.value}>{d[1]}</Text>
                  </View>
                );
              })}
            </View>
          </>
        )}
      </View>
    </>
  );
};

export function BulletPoint({text, color}) {
  return (
    <View
      style={{flexDirection: 'row', alignItems: 'center', marginVertical: 3}}>
      <View
        style={{
          height: 7,
          width: 7,
          backgroundColor: color,
          borderRadius: 10,
          marginRight: 6,
        }}
      />
      <Text style={{fontFamily: regular}}>{text}</Text>
    </View>
  );
}

export default function Feeling({positive = true, data = []}) {
  const bg = positive ? '#EAF8F5' : '#FDF2F2';
  const heading = positive ? 'Positives' : 'Negatives';
  const bullet = positive ? '#ABDDD0' : '#FAC9C9';
  const border = positive ? '#AFEDE0' : '#FAD4D4';
  return (
    <View
      style={{
        backgroundColor: bg,
        flex: 1,
        padding: 12,
        borderRadius: 6,
        marginVertical: 5,
        borderColor: border,
        borderWidth: 1,
      }}>
      <View
        style={{flexDirection: 'row', alignItems: 'center', marginBottom: 5}}>
        {positive ? <Like /> : <Dislike />}
        <Text style={{marginLeft: 10, fontFamily: semiBold}}>{heading}</Text>
      </View>
      {data.length > 0 &&
        data.map((item, i) => {
          return <BulletPoint color={bullet} text={item} />;
        })}
    </View>
  );
}

export function LinkToResources({bond}) {
  if (!bond || !bond.IM || !bond.ratingRational){ return null; }

  return (
    <View>
      <Pressable
        style={styles.infoButton}
        accessibilityRole="link"
        onPress={() => goToExternalLink(bond.IM)}>
        <Text style={styles.infoButtonText}>Information Memorandum</Text>
      </Pressable>
      <Pressable
        style={styles.infoButton}
        accessibilityRole="link"
        onPress={() => goToExternalLink(bond.ratingRational)}>
        <Text style={styles.infoButtonText}>Rating Rational</Text>
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  card: {
    backgroundColor: 'white',
    width: isMobile ? '100%' : '50%',
    elevation: 3,
    paddingHorizontal: 16,
    paddingVertical: 16,
    borderRadius: 6,
    marginTop: 15,
  },
  cardOverlay: {
    backgroundColor: 'rgb(245, 244, 242)',
    position: 'relative',
    left: 350,
    top: 250,
    zIndex: 100,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
    elevation: 4,
    shadowColor: 'black',
    shadowOpacity: 0.1,
    shadowRadius: 6,
    shadowOffset: {
      width: 2,
      height: 2,
    },
  },
  infoText: {
    color: '#637381',
    fontSize: 14,
    fontFamily: 'Inter-Regular',
    lineHeight: 20,
    marginBottom: 10,
  },
  section: {flexWrap: 'wrap', flexDirection: 'row'},
  item: {width: '50%', marginVertical: 8},
  label: {
    color: '#637381',
    fontSize: 14,
    fontFamily: 'Inter-Regular',
    marginBottom: 4,
  },
  value: {
    color: '#454F5B',
    fontSize: 15,
    fontFamily: 'Inter-SemiBold',
  },
  lastTradedButtonText: {
    color: '#637381',
    fontSize: 14,
    fontFamily: 'Inter-SemiBold',
  },
  similarHistory: {
    color: '#637381',
    fontSize: 14,
    fontFamily: 'Inter-Regular',
  },
  lastTradedButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#F8F4F5',
    paddingHorizontal: 10,
    paddingVertical: 5,
    marginTop: 20,
    borderRadius: 6,
    marginBottom: 7,
    borderColor: '#E5E5E5',
    borderWidth: 1,
    shadowColor: 'black',
    shadowOpacity: 0.3,
    shadowRadius: 4,
    shadowOffset: {
      width: 2,
      height: 2,
    },
    width: '100%',
    elevation: 4,
  },
  lastTradedInfo: {
    backgroundColor: '#F8F4F5',
    paddingHorizontal: 10,
    paddingVertical: 5,
    marginTop: 20,
    borderRadius: 6,
    marginBottom: 7,
    borderColor: '#E5E5E5',
    borderWidth: 1,
    width: '100%',
  },
  infoButton: {
    backgroundColor: '#F8F4F5',
    paddingHorizontal: 12,
    marginVertical: 4,
    paddingVertical: 5,
    borderRadius: 6,
    borderColor: '#E5E5E5',
    borderWidth: 1,
    shadowColor: 'black',
    shadowOpacity: 0.3,
    shadowRadius: 4,
    shadowOffset: {
      width: 2,
      height: 2,
    },
    width: '100%',
    elevation: 4,
  },
  infoButtonText: {
    color: '#637381',
    fontSize: 12,
    fontFamily: 'Inter-SemiBold',
    textAlign: 'center',
  },
});
