import {StyleSheet} from 'react-native';
import {regular, isMobile} from '../../utils/constants';

export const styles = StyleSheet.create({
  header: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    flexDirection: 'row',
    paddingVertical: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#DEE2E5',
    backgroundColor: '#FFF',
  },
  headerText: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
    flex: 1,
    maxWidth: 800,
  },
  profile: {
    fontFamily: regular,
    color: '#1B2A3C',
  },
  container: {
    width: '100%',
    maxWidth: 1100,
  },
  confirmSection: {
    width: '100%',
    backgroundColor: 'white',
    paddingVertical: 14,
    paddingHorizontal: 14,
    elevation: 6,
    alignSelf: 'center',
    shadowColor: 'black',
    shadowOpacity: 0.1,
    shadowRadius: 6,
    shadowOffset: {
      width: -2,
      height: -2,
    },
    alignItems: 'center',
    flexDirection: isMobile ? 'column' : 'row',
    justifyContent: 'center',
  },
  pricingSection: {
    alignItems: isMobile ? 'center' : 'flex-start',
    marginRight: isMobile ? 0 : 80,
  },
  continueButton: {
    backgroundColor: '#27AE60',
    height: 45,
    borderRadius: 24,
    alignItems: 'center',
    justifyContent: 'center',
    elevation: 4,
    marginTop: 10,
    maxWidth: 400,
    minWidth: 200,
  },
  buyHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 14,
  },
  printView: {
    width: '100%',
    maxWidth: 800,
    alignSelf: 'center',
    backgroundColor: 'white',
  },
  price: {
    fontSize: isMobile ? 18 : 26,
    fontFamily: 'Inter-SemiBold',
    color: '#454F5B',
  },
  label: {
    fontSize: 14,
    fontFamily: 'Inter-Regular',
  },
});
