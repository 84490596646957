import React, {useState, useEffect} from 'react';
import {ScrollView, SafeAreaView, View, Text, Platform} from 'react-native';
import LinearBackground from '../components/linearBackground';
import {InvestPrimary} from '../components/investment/primary';
import {InvestSecondary} from '../components/investment/secondary';
import {Phone} from '../components/phone';
import {screenHeight} from '../utils/constants';
import {IssuerLogo} from '../components/issuerLogo';
import {styles} from '../screens/detail/styles';
import { apiService } from '../utils/apiService';

export const InvestmentScreen = ({route}) => {
  const [selectedBond, setSelectedBond] = useState();
  const primary = route.params.market === 'primary';

  useEffect(() => {
    if (primary) { apiService.getIPODetails(route.params.id).then(response => setSelectedBond(response)); }
    else { apiService.getBondDetails(route.params.id).then(response => setSelectedBond(response)); }
  }, [primary]);

  if (!selectedBond) {
    return null;
  }

  return (
    <SafeAreaView>
      <View style={styles.header}>
        <View style={styles.logo}>
          <IssuerLogo name={selectedBond.nameOfIssuer} />
        </View>
        <View style={{paddingLeft: 10, flex: 1}}>
          <Text style={styles.value}>{selectedBond.nameOfIssuer}</Text>
          <Text style={{fontSize: 14}}>{selectedBond.flags}</Text>
        </View>
      </View>
      <ScrollView>
        <LinearBackground
          style={{
            width: '100%',
            height: Platform.OS === 'web' ? screenHeight : '100%',
            paddingHorizontal: 4,
            paddingBottom: 80,
          }}
        >
          {primary ? <InvestPrimary bond={selectedBond} /> : <InvestSecondary bond={selectedBond} />}
        </LinearBackground>
      </ScrollView>
      <Phone />
    </SafeAreaView>
  );
};
