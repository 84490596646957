import React, {useState, useEffect} from 'react';
import {
  SafeAreaView,
  KeyboardAvoidingView,
  TouchableWithoutFeedback,
  Keyboard,
  Pressable,
  View,
  Text,
  TextInput,
  StatusBar,
  useColorScheme,
  Platform,
} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import validator from 'validator';
import {StatusCodes} from 'http-status-codes';
import {styles} from './styles';
import useGlobalStore from '../../utils/GlobalStateStore';
import {
  GlobalAlert,
  loginStates,
  isNative,
  QuickInvest,
  goToExternalLink,
} from '../../utils/constants';
import INRLogo from '../../assets/images/INRLogo';
import {apiService} from '../../utils/apiService';

const Login = ({navigation, route}) => {
  const isDarkMode = useColorScheme() === 'dark';
  const [inputUser, setInputUser] = useState(null);
  const [password, setPassword] = useState(null);
  const [otp, setOTP] = useState(null);
  const [otpSuccess, setOTPSuccess] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const setloginStage = useGlobalStore(state => state.setloginStage);
  const setLoggedInUser = useGlobalStore(state => state.setLoggedInUser);
  const loginStage = useGlobalStore(state => state.loginStage);
  const setPersistedUser = useGlobalStore(state => state.setPersistedUser);
  const persistedUser = useGlobalStore(state => state.persistedUser);

  useEffect(() => {
    if (
      (route.params.changePassword && loginStage === loginStates.LOGGED_IN) ||
      (route.params.hash && !validator.isEmpty(route.params.hash))
    ) {
      setloginStage(loginStates.NEW_PASSWORD);
    }
  }, [loginStage]);

  const showChangePassword = () => {
    if((loginStage === loginStates.NEW_PASSWORD) && 
    ((route.params.changePassword && loginStage === loginStates.LOGGED_IN) ||
      (route.params.hash && !validator.isEmpty(route.params.hash))
    )) return true;
    else return false;
  }

  const handleLogin = (userName, pwd, token) => {
    apiService.login(userName, pwd, token).then(result => {
      if (result.responseStatus === StatusCodes.UNAUTHORIZED) { setErrorMessage('Invalid Credentials'); }
      else if (result.responseStatus === StatusCodes.OK) {
        setloginStage(result.status);
        if(result.status === loginStates.NEW_PASSWORD){
          GlobalAlert(`You're logging in for the first time or your password is expired. Do you want to set new password ?`, reVerify ,()=>{
            return null;
          })
        }else if (result.user) {
          setLoggedInUser(result.user);
          setOTPSuccess(false);
          if (isNative) {
            if (
              route.params &&
              route.params.nextScreen &&
              route.params.nextScreen === 'Detail'
            ) {
              navigation.navigate(route.params.nextScreen, route.params);
            } else {
              navigation.navigate('Listing', route.params);
            }
          } else {
            if (route.params && route.params.nextScreen) {
              window.location.href = route.params.nextScreen;
            } else {
              window.location.href = QuickInvest;
            }
          }
        }
      }
    });
  };

  const inputUserId = () => {
    if (inputUser) {
      if (!validator.isEmail(inputUser)) {
        setErrorMessage('Email address is incorrect');
      } else {
        AsyncStorage.setItem('@storage_Key', inputUser);
        setPersistedUser(inputUser);
        //Check if valid user account is there
        handleLogin(inputUser);
      }
    }
  };

  const changeUser = () => {
    AsyncStorage.removeItem('@storage_Key');
    setInputUser(null);
    setPassword(null);
    setPersistedUser(null);
    setErrorMessage(null);
    setloginStage(loginStates.LOGGED_OUT);
  };

  const reVerify = () => {
    if (persistedUser) {
      apiService.reVerifyUser(persistedUser);
    } else {
      GlobalAlert('Please enter your email.');
    }
  };

  const sendOTP = () => {
    if (persistedUser){
      apiService
        .requestLoginOtp(persistedUser)
        .then(data => GlobalAlert(data.message, setOTPSuccess(true)));
    }
    else { GlobalAlert('Please enter your email.'); }
  };

  const handleOTP = () => {
    if (!otpSuccess) { sendOTP(); }
    else { setOTPSuccess(false); }
  };

  const newPassword = () => {
    apiService
      .setNewPassword(
        persistedUser,
        password,
        confirmPassword,
        route.params.hash,
      )
      .then(result => {
        if (result.status === StatusCodes.OK) {
          navigation.setParams({changePassword: false, hash: null});
          setloginStage(loginStates.LOGGED_OUT);
        }
      })
      .catch(error => {
        if (error.response && error.response.status === StatusCodes.BAD_REQUEST){
          setErrorMessage(error.response.data.message);
        }
      });
  };

  const onEnterPress = (evt, press) => {
    setErrorMessage(null);
    if (press || evt.key === 'Enter') {
      if (!password || password.length <= 0) {
        setErrorMessage('Password cannot be blank');
      } else if (showChangePassword()) {
        if (
          !confirmPassword ||
          confirmPassword.length <= 0 ||
          password !== confirmPassword
        ) {
          setErrorMessage('Both the passwords should match');
        } else if (!validator.isStrongPassword(password)){
          setErrorMessage('Password does not match the security criterion.');
        }
        else if (persistedUser) {
          newPassword();
        }
      } else if (persistedUser) {
        handleLogin(persistedUser, password);
      }
    }
  };

  const onEnterPressOTP = (evt, press) => {
    if (press || evt.key === 'Enter') {
      if (!otp || otp.length <= 0) {
        setErrorMessage('OTP cannot be blank');
      } else if (persistedUser) {
        handleLogin(persistedUser, null, otp);
      }
    }
  };

  /* navigate to QuickInvest for loggedin user */
  if (loginStage === loginStates.PENDING_VERIFICATION) {
    /* in case user account not verified */
    return (
      <SafeAreaView style={styles.main}>
        <StatusBar barStyle={isDarkMode ? 'light-content' : 'dark-content'} />
        <View style={styles.container}>
          <Text style={styles.userID}>{persistedUser}</Text>
          <Pressable accessibilityRole="link" onPress={changeUser}>
            <Text style={{color: '#454F5B'}}>Change User ?</Text>
          </Pressable>
          <Text style={styles.userID}>
            Your account is unverified. Please check your mailbox for
            verification message.
          </Text>
          <Pressable
            accessibilityRole="button"
            onPress={reVerify}
            style={styles.loginButton}>
            <Text style={styles.login}>Send Again</Text>
          </Pressable>
        </View>
      </SafeAreaView>
    );
  } else if (loginStage === loginStates.LOGGING_IN) {
    /* initial loading */
    return (
      <SafeAreaView style={styles.main}>
        <StatusBar barStyle={isDarkMode ? 'light-content' : 'dark-content'} />
        <View style={styles.container} > <INRLogo /> </View>
      </SafeAreaView>
    );
  } else {
    /* finally the main login screen here */
    return (
      <SafeAreaView style={styles.main}>
        <StatusBar barStyle={isDarkMode ? 'light-content' : 'dark-content'} />
        <KeyboardAvoidingView
          behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
          style={styles.container}>
          <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
            <>
              <INRLogo />
              { showChangePassword() ? (
                <Text style={styles.h4}>Change Password</Text>
              ) : (
                <Text style={styles.h4}>Sign In</Text>
              )}
              <View
                style={{
                  paddingBottom: 16,
                  paddingHorizontal: 10,
                }}>
                <View style={{alignItems: 'center'}}>
                  {(!persistedUser ||
                    (route.params.hash && !validator.isEmpty(route.params.hash) &&
                      (!inputUser || validator.isEmpty(inputUser)))) ? (
                    <TextInput
                      style={styles.input}
                      placeholder="Email"
                      placeholderTextColor="#C1C1C1"
                      onBlur={inputUserId}
                      onChangeText={setInputUser}
                      autoFocus={true}
                      clearTextOnFocus={true}
                      keyboardType="email-address"
                      autoCompleteType="email"
                    />
                  ) : (
                    <>
                      <Text style={styles.userID}>{persistedUser}</Text>
                      {!route.params.changePassword ? (
                        <Pressable
                          accessibilityRole="link"
                          onPress={changeUser}>
                          <Text style={styles.changeUser}>Change User ?</Text>
                        </Pressable>
                      ) : null}
                    </>
                  )}
                </View>
                { showChangePassword() ? (
                  route.params.changePassword ? (
                    <Text style={styles.messageText}>
                      Kindly set a new password for your account. Your password
                      should be:
                      <ul>
                        <li>Minimum 8 characters long</li>
                        <li>Minimum 1 uppercase and 1 lowercase alphabet </li>
                        <li>Mimimum 1 digit and 1 special character</li>
                        <li>Different from last 5 passwords</li>
                      </ul>
                    </Text>
                  ) : (
                    <Text style={styles.messageText}>
                      You're logging in for the first time or your password is
                      expired. Your password should be:
                      <ul>
                        <li>Minimum 8 characters long</li>
                        <li>Minimum 1 uppercase and 1 lowercase alphabet </li>
                        <li>Mimimum 1 digit and 1 special character</li>
                        <li>Different from last 5 passwords</li>

                      </ul>
                    </Text>
                  )
                ) : null}
              </View>
              {otpSuccess ? (
                <TextInput
                  style={styles.input}
                  placeholderTextColor="#C1C1C1"
                  placeholder={'OTP'}
                  onChangeText={setOTP}
                  onKeyPress={onEnterPressOTP}
                  autoFocus={persistedUser !== null}
                  clearTextOnFocus={true}
                  secureTextEntry={true}
                />
              ) : (
                <TextInput
                  style={styles.input}
                  placeholderTextColor="#C1C1C1"
                  placeholder={'Password'}
                  onChangeText={setPassword}
                  onKeyPress={onEnterPress}
                  autoFocus={persistedUser !== null}
                  clearTextOnFocus={true}
                  secureTextEntry={true}
                />
              )}
              { showChangePassword() ? (
                <TextInput
                  style={styles.input}
                  placeholderTextColor="#C1C1C1"
                  placeholder="Confirm Password"
                  onChangeText={setConfirmPassword}
                  onKeyPress={onEnterPress}
                  clearTextOnFocus={true}
                  secureTextEntry={true}
                />
              ) : null}
              <View style={styles.submitContainer}>
                <Pressable
                  accessibilityRole="button"
                  nativeID="login"
                  onPress={e =>
                    otpSuccess
                      ? onEnterPressOTP(e, true)
                      : onEnterPress(e, true)
                  }
                  style={styles.loginButton}>
                  <Text style={styles.login}>
                    { showChangePassword()
                      ? 'Submit'
                      : 'Login'}
                  </Text>
                </Pressable>
              </View>
              <Text style={styles.errorText}>{errorMessage}</Text>
              { showChangePassword() ? null : (
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: 300,
                    justifyContent: 'space-between',
                  }}>
                  <Pressable style={styles.OTPbutton} onPress={handleOTP}>
                    <Text style={[styles.subscribeText, {color: '#31bc98'}]}>
                      {otpSuccess ? 'Login with email' : 'Login with OTP'}
                    </Text>
                  </Pressable>
                  <Pressable
                    accessibilityRole="link"
                    onPress={otpSuccess ? sendOTP : reVerify}>
                    <Text style={[styles.subscribeText, {color: '#808080'}]}>
                      {otpSuccess ? 'Resend OTP' : 'Forgot Password ?'}
                    </Text>
                  </Pressable>
                </View>
              )}
              {Platform.OS !== 'ios' && <SignUpSection />}
            </>
          </TouchableWithoutFeedback>
        </KeyboardAvoidingView>
        {isNative && (
          <View
            style={{
              height: '15%',
              width: '100%',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}>
            <Pressable
              accessibilityRole="link"
              style={{
                backgroundColor: '#FAF3F0',
                paddingHorizontal: 20,
                paddingVertical: 4,
                borderRadius: 6,
              }}
              onPress={() => {
                goToExternalLink('https://inrbonds.com/privacy-policy');
              }}>
              <Text
                style={{
                  fontWeight: '400',
                  fontSize: 14,
                  color: '#3F2E3E',
                }}>
                Privacy Policy
              </Text>
            </Pressable>
          </View>
        )}
      </SafeAreaView>
    );
  }
};

export function SignUpSection() {
  return (
    <View style={{flexDirection: 'row'}}>
      <Text style={styles.subscribeText}>Don't have an account ?</Text>
      <Pressable
        accessibilityRole="link"
        onPress={() => {
          goToExternalLink('https://inrbonds.com/signup');
        }}>
        <Text style={[styles.subscribeText, {color: '#31bc98'}]}>Sign Up</Text>
      </Pressable>
    </View>
  );
}

export default Login;
